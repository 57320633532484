import React from 'react';
import './LoginForm.css';
import { Card, Button, Form, Image } from 'react-bootstrap'
import { observer, inject } from 'mobx-react';
import axios from 'axios';

/** Below is my new react native code but the history section doesnt route to the new loggedin page**/
class LoginFormNew extends React.Component {

    constructor(props,) {
        super(props);
        this.state = {
            username: "",
            password: "",
            successMessage: "",
            refresh_token: null,
            successMessageHidden: true,
            error: "",
            errorHidden: true,
            LoadingHidden: true,
        };

    };

    componentDidMount() {
        localStorage.clear()
    }

    handleChange = (e) => {
        const { id, value } = e.target
        this.setState(prevState => ({
            ...prevState,
            [id]: value
        }))
    }

    errorShow(errorMessage, errorHidden) {
        this.setState({ 'error': errorMessage, 'errorHidden': errorHidden })
    }

    successShow(successMessage, successHidden) {
        this.setState(prevState => ({
            ...prevState,
            'successMessage': successMessage,
            'successMessageHidden': successHidden
        }))
    }

    LoadingShow(LoadingHidden) {
        this.setState(prevState => ({
            ...prevState,
            'LoadingHidden': LoadingHidden
        }))
    }

    sendLoginAPIRequest() {
        this.LoadingShow(false)
        this.errorShow('', true)
        this.successShow('', true)

        if (this.state.username.length && this.state.password.length) {
            var data = JSON.stringify({ "username": this.state.username, "password": this.state.password });
            axios({
                method: 'post',
                url: this.props.configStore.API_BASE_URL + 'Login',
                headers: { 'Content-Type': 'application/json', "Access-Control-Allow-Origin": "*" },
                data: data
            })
                .then((returnedData) => {
                    if (returnedData.status === 200) {
                        if (!returnedData.data['isactive']) {
                            this.successShow('', true)
                            this.LoadingShow(true)
                            this.errorShow('Your account has not been activated by admin, please try again soon', false)
                            this.props.profileStore.resetStore()
                        }
                        else if (returnedData.data['accessRevoked']) {
                            this.successShow('', true)
                            this.LoadingShow(true)
                            this.errorShow('Your account has been disabled', false)
                            this.props.profileStore.resetStore()
                        }
                        else {
                            this.LoadingShow(true)
                            this.props.profileStore.updateToken(returnedData.data['token'])
                            this.props.profileStore.updateRefreshToken(returnedData.data['refresh_token'])
                            this.props.profileStore.updateUsername(this.state.username)
                            this.props.profileStore.updateProfile(true, returnedData.data['isadmin'], returnedData.data['firstname'], returnedData.data['lastname'], returnedData.data['isactive'], returnedData.data['loginID'], returnedData.data['team'], returnedData.data['team2'])
                            /*this.props.profileStore.loginID = returnedData.data['loginID'][0]
                            this.props.profileStore.admin = returnedData.data['isadmin'][0]
                            this.props.profileStore.loggedin = true
                            this.props.profileStore.isactive = returnedData.data['isactive'][0]
                            this.props.profileStore.firstname = returnedData.data['firstname'][0]
                            this.props.profileStore.lastname = returnedData.data['lastname'][0]
                            this.props.profileStore.teams.push(returnedData.data['team'][0])
                            this.props.profileStore.teams.push(returnedData.data['team2'][0])*/
                            
                        }
                    }
                    else {
                        this.LoadingShow(true)
                        this.errorShow('Incorrect Username or Password', false)
                        this.successShow('', true)
                        this.props.profileStore.resetStore()
                    }

                })
                .catch((returnedData) => {
                    this.LoadingShow(true)
                    this.errorShow('Incorrect Username or Password - Unsuccessful', false)
                    this.successShow('', true)
                    this.props.profileStore.resetStore()
                })

        } else {
            this.LoadingShow(true)
            this.errorShow('Enter a valid username and password', false)
            this.successShow('', true)
            this.props.profileStore.resetStore()
        }
    }

    handleSubmitClick = (e) => {
        e.preventDefault();
        this.sendLoginAPIRequest()
    }

    render() {
        return (
            <div>
                <Card id='loginCard'>
                    <Card.Header id="loginHeader" as='h5'>Roosters Login: </Card.Header>
                    <Card.Body>
                        <Form hidden={!this.state.LoadingHidden}>
                            <Form.Group>

                                <Form.Text>This will allow you to vote for player of the day and issue players with fines, which will then be raised in each teams fine's meetings.</Form.Text>

                                <Form.Label>Email: </Form.Label>
                                <Form.Control id="username" placeholder="Enter a username" value={this.state.username} onChange={this.handleChange} />
                                <Form.Label>Password: </Form.Label>
                                <Form.Control type="password" id="password" placeholder="Password" minLength='5' value={this.state.password} onChange={this.handleChange} />

                                <div style={{ textAlign: 'center' }}>
                                    <Button id='loginbutton' variant='outline-success' style={{ marginTop: '1rem', minWidth: '30%' }} type="submit" onClick={this.handleSubmitClick}>Login</Button>
                                    <Form.Text>Don't have an account yet?</Form.Text>
                                    <Form.Text><a href="/Registration">Register Here</a></Form.Text>
                                </div>

                            </Form.Group>
                        </Form>
                        <div id='contents'>
                            <Image hidden={this.state.LoadingHidden} id="LoadingImage" src={process.env.PUBLIC_URL + '/Loading.jpg'} fluid ></Image>
                            <Card.Title id='loadingLabel' hidden={this.state.LoadingHidden}>Loading. . . .</Card.Title>
                        </div>

                        <div className="alert alert-success mt-2" role="alert" hidden={this.state.successMessageHidden}>
                            {this.state.successMessage}
                        </div>
                        <div className="alert alert-danger mt-2" role="alert" hidden={this.state.errorHidden}>
                            {this.state.error}
                        </div>
                    </Card.Body>
                </Card>

            </div>

        )
    }


}
export default inject('profileStore', 'configStore')(observer(LoginFormNew))