import { makeAutoObservable } from "mobx"
import axios from "axios"
import configStore from "./ConfigStore";
import profileStore from "./ProfileStore";

class ChatStore {
    Messages = [{loginID:1,firstname:'No',lastname:'Messages',message:'There is currently no messages. Start the chat by sending a message below.',date:'01/01/2024',time:'11.30am'}]
    SelectedTeam = 'Mens DIV1'

    constructor() {
        makeAutoObservable(this)
    }

    updateMessages(data){
      this.Messages = data
    }
    updateSelectedTeam(data){
      this.SelectedTeam = data
    }

    getChatMessages() {
        axios({
            method: 'get',
            url: configStore.API_BASE_URL + 'chatmessages',
            headers: { 'Content-Type': 'application/json','Authorization': 'Bearer ' + profileStore.token},
        })
            .then((returnedData) => {
                if (returnedData.status === 200) {
                  this.updateMessages(returnedData.data)
                }
            })
            .catch(function (error){
              if (error.response.status === 401) {
                profileStore.resetStore()
                //console.log(error.response.data); //Your token has expired please login again
                //console.log(error.response.status); //401
              }
            })
    }

  SendMessage(MessageToSend, Team,LoginID) {
    if (MessageToSend) {
      var data = JSON.stringify({ "LoginID": LoginID, "team": Team,"message":MessageToSend});
      axios({
        method: 'post',
        url: configStore.API_BASE_URL + 'chatmessages',
        headers: { 'Content-Type': 'application/json','Authorization': 'Bearer ' + profileStore.token},
        data: data
      })
        .then((returnedData) => {
          if (returnedData.status === 200) {
            this.getChatMessages()
          }
        })
        .catch(function (error){
          if (error.response.status === 401) {
            profileStore.resetStore()
            //console.log(error.response.data); //Your token has expired please login again
            //console.log(error.response.status); //401
          }
          else{
            alert("Hmm that message couldnt be sent - Please try again.")
          }
        })
       
    } 
  }
    
}
const chatStore = new ChatStore()
export default chatStore