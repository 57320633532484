import React from 'react';
import './Profile.css';
import { Card, Button } from 'react-bootstrap'
import { inject, observer } from 'mobx-react';
import axios from 'axios';

class ProfileForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            errorHidden: true,
            editProfileInd: false,
            success: null,
            successHidden: true,
            secondTeamHidden: true,
            secondTeam: props.configStore.TeamsList[0]
        };
    };

    errorShow(errorMessage, errorHidden) {
        this.setState(prevState => ({
            ...prevState,
            'error': errorMessage,
            'errorHidden': errorHidden
        }))
    }

    successShow(successMessage, successHidden) {
        this.setState(prevState => ({
            ...prevState,
            'success': successMessage,
            'successHidden': successHidden
        }))
    }

    handleChange = (e) => {
        const { id, value } = e.target
        this.setState(prevState => ({
            ...prevState,
            [id]: value
        }))
    }

    handleAddTeamChange = (e) => {
        if (this.state.secondTeamHidden) {
            this.setState({ secondTeamHidden: false })
        } else {
            this.setState({ secondTeamHidden: true })
        }

    }

    returnteams() {
        let temp = []
        for (let i = 0; i < this.props.configStore.TeamsList.length; i++) {
            temp.push(<option>{this.props.configStore.TeamsList[i]}</option>)
        }
        return temp
    }


    adminRender() {
        if (!this.props.profileStore.admin) {
            return (
                "Unfortunately, you are not admin.".toUpperCase()
            )
        }
        else {
            return "Yes, you are an administrator.".toUpperCase()
        }
    }

    addsecondteam(value) {
        this.successShow('', true)
        this.errorShow('', true)
        var data = JSON.stringify({ "loginID": this.props.profileStore.loginID, "secondTeam": value });
        axios({
            method: 'post',
            url: this.props.configStore.API_BASE_URL + 'addSecondTeam',
            headers: { 'Content-Type': 'application/json','Authorization': 'Bearer ' + this.props.profileStore.token},
            data: data
        })
            .then((returnedData) => {

                if (returnedData.status === 200) {
                    this.setState({ secondTeamHidden: true, successHidden: false, success: 'Your second team has been added. Please login again for this to take effect.' })
                }
                else {
                    this.setState({ secondTeamHidden: true, successHidden: false, success: 'Your second team has been added. Please login again for this to take effect.' })
                }
            })
            .catch(function (error){
                if (error.response.status === 401) {
                    this.props.profileStore.resetStore()
                  //console.log(error.response.data); //Your token has expired please login again
                  //console.log(error.response.status); //401
                }
                else{
                    this.setState({ secondTeamHidden: false, errorHidden: false, errorMessage: 'Hmm that didnt work' })
                }
            })
    }

    handleSecondTeam() {
        if (this.props.profileStore.teams[1] === null) {
            return ""
        }
        else {
            return this.props.profileStore.teams[1]
        }
    }

    secondTeamAlreadyAdded() {
        if (this.props.profileStore.teams[1] === null) {
            if (this.props.profileStore.teams[0] === 'SUPPORTER'){ //Supporters are no able to add another team...
                return true
            }
            else{
                return false
            }
        }
        else {
            return true
        }
    }

    render() {
        return (

            <Card id='profileCard' style={{ minWidth: '17rem' }}>
                <Card.Header id='profileHeader'>Profile: </Card.Header>

                <Card.Body id='profileBody'>
                    <Card.Title>Username: </Card.Title>
                    <Card.Text>{this.props.profileStore.username.toUpperCase()}</Card.Text>

                    <Card.Title>First Name: </Card.Title>
                    <Card.Text>{this.props.profileStore.firstname.toUpperCase()}</Card.Text>

                    <Card.Title>Last Name: </Card.Title>
                    <Card.Text>{this.props.profileStore.lastname.toUpperCase()}</Card.Text>

                    <Card.Title>Teams Assigned: </Card.Title>
                    <Card.Text>{this.props.profileStore.teams[0]}</Card.Text>
                    <Card.Text>{this.handleSecondTeam()}</Card.Text>

                    <Card.Title>Admin: </Card.Title>
                    <Card.Text>{this.adminRender()}</Card.Text>

                    <div id='CentraliseBtn'>
                        <Button variant='outline-primary'hidden={!this.state.secondTeamHidden || this.secondTeamAlreadyAdded() || !this.state.successHidden} onClick={this.handleAddTeamChange}>Add Additional Team</Button>
                    </div>

                    <Card.Title hidden={this.state.secondTeamHidden}>Add additional team: </Card.Title>
                    <Card.Text hidden={this.state.secondTeamHidden}>If you have already added a second team and have not signed out and back in then this will not take effect.</Card.Text>
                    <div className="form-group" hidden={this.state.secondTeamHidden}>
                        <select className="form-control" id='secondTeam' value={this.state.secondTeam} onChange={(e) => this.handleChange(e)}>
                            {this.returnteams()}
                        </select>
                    </div>
                    <div id='CentraliseBtn' hidden={this.state.secondTeamHidden}>
                        <Button variant="warning" title='Careful...This cannot be un-done' onClick={() => { this.addsecondteam(this.state.secondTeam) }}>Add Team</Button>
                    </div>
                </Card.Body>

                <div id='profileSuccess' className="alert alert-danger mt-2" role="alert" hidden={this.state.errorHidden}>
                    {this.state.error}
                </div>
                <div className="alert alert-success mt-2" role="alert" hidden={this.state.successHidden}>
                    {this.state.success}
                </div>
            </Card>
        )
    }
}
export default inject('profileStore','configStore')(observer(ProfileForm))