import React from 'react';
import './AboutUs.css';
import { Card, Image, Table } from 'react-bootstrap'

export default class DeveloperProfileCard extends React.Component {
    render() {
        return (
            <Card className="MainCard" >
                <Card.Header as="h5">About Us: </Card.Header>
                <Card.Body id="CardBody1">
                    <Image className="MEIMAGE" src={process.env.PUBLIC_URL + '/Roosters Logo.jpg'} fluid />
                    <Table borderless size='sm' responsive>
                        <tbody>
                            <tr>
                                <td>
                                    <Card.Title>Who are the Roosters:</Card.Title>
                                </td>
                                <td>
                                    <Card.Text>Roosters Hockey is a field hockey club based in Gold Coast</Card.Text>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <Card.Title>Whats this about? </Card.Title>
                                </td>
                                <td>
                                    <Card.Text>This space is where we look to recognise and/or fine our players each week</Card.Text>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        )
    }
}