import React from 'react';
import Header from '../../components/Header/Header'
import RegisterForm from '../../components/RegisterForm/RegisterForm'
import { useHistory } from "react-router-dom";

function Signup() {


return (
  <div>
      <Header title="Registration" name="" loginshow={false} history={useHistory()}/>
    
      <div className="container d-flex align-items-center flex-column">
        <RegisterForm />
      </div>

  </div>


  )  
}
export default Signup;



