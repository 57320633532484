import React from 'react';
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import { Popover, OverlayTrigger, Image } from 'react-bootstrap';
import ProfileForm from '../../components/Profile/Profile'
import './Header.css'

export default class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Headername: "Roosters Fines"
        };
    };

    popover() {
        return (
            <Popover id="popover-basic">
                <ProfileForm />
            </Popover>
        )

    }

    render() {

        if (this.props.loginshow === false) {
            return (

                <Navbar id="header" collapseOnSelect expand='lg' bg="light" variant="light">
                    <Image id="ImageHeader" src={process.env.PUBLIC_URL + '/Roosters Logo.jpg'} fluid ></Image>
                    <Navbar.Brand id="HeaderButtonMain" className="HeaderButtonMain" href="/">{this.state.Headername}</Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="mr-auto">
                            {/*<Nav.Link className="HeaderButton" href="/Menu">Login</Nav.Link>*/}
                            <Nav.Link className="HeaderButton" href="/Registration">Register</Nav.Link>
                            <Nav.Link className="HeaderButton" href="/Loggedin">Login</Nav.Link>
                        </Nav>

                        <Nav>
                            <Nav.Item className="HeaderButton" style={{ right: 'true' }}>
                                {this.props.title}
                            </Nav.Item>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            )
        }
        else { //<Nav.Link className="HeaderButton" href="/Lights">Lights</Nav.Link>
            return (
                <Navbar id="header" collapseOnSelect expand='lg' bg="light" variant="light">
                    <Image id="ImageHeader" src={process.env.PUBLIC_URL + '/Roosters Logo.jpg'} fluid ></Image>
                    <Navbar.Brand id="HeaderButtonMain" className="HeaderButtonMain" href="/">{this.state.Headername}</Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="mr-auto">
                            <Nav.Link className="HeaderButton" href="/Loggedin">Home</Nav.Link>
                        </Nav>

                        <Nav>
                            <Nav.Link className="HeaderButton" href="/">Log Out</Nav.Link>
                            <OverlayTrigger trigger='click' rootClose placement='auto-end' overlay={this.popover()}>
                                <Nav.Link className="HeaderButton">
                                    {this.props.title}, {this.props.name}
                                </Nav.Link>
                            </OverlayTrigger>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            )
        }
    }
}




























/*

function Header(props) {
    let history = useHistory()

    const [state,setState] = useState({
        Headername : "Chads App"
    })

    const popover = (
        <Popover id="popover-basic">
            <ProfileForm/>
        </Popover>
      );



    const TokenCheck = () => {
    
       axios({
            method:'get',
            url:API_BASE_URL+'ChadsAPI/CheckToken',
            headers: { 
                'Authorization': 'Bearer ' + localStorage.getItem('token') //can use - ACCESS_TOKEN_NAME
              }
        })
        .then((returnedData)=>{
            if (returnedData.status === 200){

            }
            else{
                history.push('/login')
            }

        })
        .catch(()=>{
            localStorage.clear()
            history.push('/Login')
            
        })

    }


    const handleClick =(e) => {
        e.preventDefault();
        TokenCheck()
    }


    if (props.loginshow === false) {
        return (
            
            
            <Navbar collapseOnSelect expand='lg' bg="dark" variant="dark">
            
            
                <Navbar.Brand href="/">{state.Headername}</Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto">
                        <Nav.Link href="/Login">Login</Nav.Link>
                        <Nav.Link href="/Signup">Sign-up</Nav.Link>
                    </Nav>
                    
                    <Nav>
                        <Nav.Item style={{right:'true',color:'white'}}>
                            {props.title}
                        </Nav.Item>
                    </Nav>
                                
                </Navbar.Collapse>

            </Navbar>

        )

    }
    else {
        return(
            <Navbar collapseOnSelect expand='lg' bg="dark" variant="dark">

                <Navbar.Brand href="/">{state.Headername}</Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto">
                    <Nav.Link href="/LoggedIn">Home</Nav.Link>
                    <Nav.Link href="/Lights">Lights</Nav.Link>
                    </Nav>
                    
                    
                    <Nav>
                        <OverlayTrigger trigger='click' rootClose placement='auto-end' onClick={handleClick} overlay={popover}>
                            <Nav.Link style={{marginTop:'0',marginBottom:'0'}} onClick={handleClick}>
                                <p style={{color:'white'}}>{props.title} {props.name} </p>
                            </Nav.Link>
                        </OverlayTrigger>
                    </Nav>        
                </Navbar.Collapse>
            </Navbar>


        )


    }

}
export default Header;




*/