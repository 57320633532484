import React from 'react';
import Header from '../../components/Header/Header'
import './Home.css'
import { Image } from 'react-bootstrap'
function Home() {
  localStorage.clear()
  return (
    <div>
      <Header title="Home" name="" loginshow={false} />
      <div id="contents">
        <Image id="MainImageHome" src={process.env.PUBLIC_URL + '/Roosters Logo.jpg'} fluid ></Image>
        <hr />
        <h1 id="Header1">Welcome To Roosters Fines</h1>
        <h5 className="labels" style={{ color: 'white' }}>Where we reward, recognise and fine our chooks</h5>
      </div>
    </div>
  )
}
export default Home;