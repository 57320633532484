import { makeAutoObservable} from "mobx"

class ProfileStore {
    username = ""
    loggedin=false
    admin=false
    firstname=""
    lastname=""
    isactive=false
    loginID=0
    token=''
    refresh_token=''
    teams=[]

    
    constructor() {
        makeAutoObservable(this)
    }
    
    updateUsername(username) {
        this.username = username
    }
    updateToken(token){
        this.token = token
    }

    updateRefreshToken(refreshToken){
        this.refresh_token = refreshToken
    }

    resetStore(){
        this.loggedin=false
        this.admin=false
        this.firstname=""
        this.lastname=""
        this.isactive=false
        this.loginID=0
        this.token=''
        this.refresh_token=''
        this.teams=[]
    }
    
    getToken(){
        return this.token
    }

    updateProfile(loggedin,admin,firstname,lastname,isactive,loginID,team1,team2) {
        this.loggedin=loggedin
        this.admin=admin
        this.firstname=firstname
        this.lastname=lastname
        this.isactive=isactive
        this.loginID=loginID
        this.teams.push(team1)
        this.teams.push(team2)
    }

}


const profileStore = new ProfileStore()
export default profileStore

