import React from 'react';
import { Card, Button, Form } from 'react-bootstrap'
import axios from 'axios';
import './IssueFine.css'
import { observer,inject } from 'mobx-react';
import profileStore from '../../Stores/ProfileStore';

class IssueFineForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      issueToLoginID: '',
      fineDescription: '',
      fineAmount: '',
      issuedByLoginID: '',
      hidethanks: true,
      errorhidden: true,
      fineid: 0,
      APICallInProgress:false
    };
  };

  SendFine() {
    if (this.state.fineDescription && this.props.issueToLoginID) {
      if (!this.state.APICallInProgress) {
        this.setState({APICallInProgress:true})
        var data = JSON.stringify({
          "issueToLoginID": this.props.issueToLoginID,
          "fineDescription": this.state.fineDescription,
          "fineAmount": this.state.fineAmount,
          "issuedByLoginID": profileStore.loginID,
          'team': this.props.team
        });

        axios({
          method: 'post',
          url: this.props.configStore.API_BASE_URL + 'IssueFine',
          headers: { 'Content-Type': 'application/json','Authorization': 'Bearer ' + this.props.profileStore.token},
          data: data
        })
          .then((returnedData) => {

            if (returnedData.status === 200) {
              this.setState({ 'hidethanks': false, 'errorhidden': true, 'fineid': returnedData.data.fineid })
            }
            else {
              this.setState({ 'hidethanks': true, 'errorhidden': false, 'fineid': 0 })
            }
            this.setState({APICallInProgress:false})
          })
          .catch(function (error){
            if (error.response.status === 401) {
                this.props.profileStore.resetStore()
              //console.log(error.response.data); //Your token has expired please login again
              //console.log(error.response.status); //401
            }
            else{
              this.setState({ 'hidethanks': true, 'errorhidden': false, 'fineid': 0 })
              this.setState({APICallInProgress:false})
            }
        })
        }
    } else {
      this.setState({ 'errorhidden': false, 'fineid': 0 })
      this.setState({APICallInProgress:false})
    }
  }

  handleIssueFinesChange = (e) => {
    const { id, value } = e.target
    this.setState(prevState => ({
      ...prevState,
      [id]: value
    }))
  }

  render() {
    return (

      <Card id='mainAdminCard' size='small'>
        <Card.Header id="AdminHeader" as="h5" style={{ textAlign: 'left' }}>Issue a Fine:</Card.Header>
        <Card.Body id='mainAdminsbody'>
          Issuing a fine to: {this.props.issueTofirstname} {this.props.issueTolastname}
          <Form>
            <Form.Group>

              <Card.Title style={{ marginTop: '1rem' }}>Description:</Card.Title>
              <textarea className="form-control" id="fineDescription" placeholder="Whats the reason for this fine?" onChange={this.handleIssueFinesChange} value={this.state.fineDescription}></textarea>

              <Card.Title style={{ marginTop: '1rem' }}>Suggested Amount: </Card.Title>
              <Card.Subtitle style={{ marginTop: '1rem' }}>This can be changed by the fines master when they approve the fine. If you want, you can leave it as 0.</Card.Subtitle>
              <Form.Control id="fineAmount" placeholder="0.00" type='money' onChange={this.handleIssueFinesChange} value={this.state.fineAmount}></Form.Control>

              <Button id='IssuefineBtn' style={{ marginTop: '1rem' }} variant="primary" /*type="submit"*/ onClick={() => this.SendFine()} hidden={!this.state.hidethanks}>Submit</Button>
            </Form.Group>
          </Form>

          <div className="alert alert-success mt-2" role="alert" hidden={this.state.hidethanks}>
            <p>Thank you, this will come up in our fines meeting</p>
          </div>

          <div className="alert alert-danger mt-2" role="alert" hidden={this.state.errorhidden}>
            <p>That didnt work please try again</p>
          </div>
        </Card.Body>

      </Card>

    );
  }
}

export default inject('configStore','profileStore') (observer(IssueFineForm))


