import { makeAutoObservable } from "mobx"

class NotificationStore {
    isIOS = false

    constructor() {
        makeAutoObservable(this)
    }

    askForApproval() {
        this.IsIOS()
        if(!this.isIOS){
            Notification.requestPermission(permission => {
                if (permission === 'granted') {
                    this.createNotification('Any notifications will show up here...', 'Up the chooks!!');
                }
            });
        }

    }

    createNotification(title, text, icon) {
        this.IsIOS()
        if(!this.isIOS){ //Only show on windows devices for now
            if (Notification.permission === "granted") {
                new Notification(title, {body: text, icon});
            }
        }
    }
    
    IsIOS(){
        var isIOS = /iPad|iPhone|iPod|Macintosh/.test(navigator.userAgent) && !window.MSStream;
        this.isIOS = isIOS ? true : false
    }
}
const notificationStore = new NotificationStore()
export default notificationStore
