import React from 'react';
import './ConfigManagement.css';
import { Card, Button, Form } from 'react-bootstrap'
import { observer, inject } from 'mobx-react';

class ConfigManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Title: this.props.configStore.HeaderTitle,
            Body: this.props.configStore.HeaderBody
        };
    };

    handleChange = (e) => {
        const { id, value } = e.target
        this.setState(prevState => ({
            ...prevState,
            [id]: value
        }))
    }

    handleUpdateClick = (e) => {
        e.preventDefault();
        this.props.configStore.updateHeader(this.state.Title, this.state.Body, this.props.profileStore.loginID)
    }

    render() {
        return (
            <Card id='mainAdminCard' size='small'>
                <Card.Header id="AdminHeader" as="h5" style={{ textAlign: 'left' }}>Site Banner:</Card.Header>
                <Card.Body id='mainAdminsbody'>
                    <Card.Text>
                        When all users login to the site they will see a heading with the information below. This can be good for any upcoming events/games. Please note that this is site wide and not specific to a team or user.
                    </Card.Text>
                    <Form>
                        <Card.Text>New Title:</Card.Text>
                        <Form.Control id="Title" placeholder="Enter the title" value={this.state.Title} onChange={this.handleChange} />
                        <Card.Text style={{marginTop:'1rem'}}>New Message:</Card.Text>
                        <Form.Control id="Body" placeholder="Enter the new message" value={this.state.Body} onChange={this.handleChange} />
                        <div style={{ textAlign: 'center' }}>
                            <Button variant='outline-success' style={{ marginTop: '1rem', minWidth: '30%' }} hidden={this.props.configStore.APIRunning} onClick={this.handleUpdateClick}>Update</Button>
                            <Card.Title style={{ marginTop: '1rem'}} id='loadingLabel' hidden={!this.props.configStore.APIRunning}>Loading... Please Wait</Card.Title>
                        </div>
                    </Form>
                </Card.Body>
            </Card>
    )}
}
export default inject('profileStore', 'configStore')(observer(ConfigManagement))