import React from 'react';
import Alert from 'react-bootstrap/Alert'
import './Secondary_Header.css'
import { observer, inject } from 'mobx-react';

class SecondaryHeader extends React.Component {
    constructor(props) {
        super(props);
        props.configStore.getHeader() //to avoid hitting the API repeatedly we can ad this here - itll only run once.
    }
    
    Handledismissbutton = () => {
        this.props.configStore.updateHeaderHidden()
    };

    render() {
        return (
            <Alert id='alert' variant="secondary" onClose={() => this.Handledismissbutton()} hidden={this.props.configStore.HeaderHidden} dismissible>
                <Alert.Heading id='alertHeading' as='h4'>{this.props.configStore.HeaderTitle}</Alert.Heading>
                <div id="secondaryHeader">
                    {this.props.configStore.HeaderBody}
                </div>
            </Alert>
        )
    }
}
export default inject('profileStore','configStore')(observer(SecondaryHeader))
