import { makeAutoObservable } from "mobx"
import axios from 'axios';
import profileStore from "./ProfileStore";

class ConfigStore {
    HeaderTitle = ""
    HeaderBody = ""
    HeaderHidden = true
    APIRunning = false

    API_BASE_URL="http://roostersfinesapi.chadsapps.com:3001/ChadsAPI/"//"http://127.0.0.1:5000/ChadsAPI/"//"http://roostersfinesapi.chadsapps.com:3001/ChadsAPI/" //"https://chooksapi.azurewebsites.net/ChadsAPI/"//"https://chooksapi.herokuapp.com/ChadsAPI/"

    TeamsList = [
        'Mens DIV1',
        'Mens DIV2',
        'Mens DIV3 Orange',
        'Mens DIV3 Blue'
     ]
     
     TeamsListRegistration = [
        'Mens DIV1',
        'Mens DIV2',
        'Mens DIV3 Orange',
        'Mens DIV3 Blue',
        'SUPPORTER'
     ]
     
     rounds = [
        "10/03 - Game 1",
        "17/03 - Game 2",
        "07/04 - Game 3",
        "14/04 - Game 4",
        "21/04 - Game 5",
        "28/04 - Game 6",
        "05/05 - Game 7",
        "12/05 - Game 8",
        "19/05 - Game 9",
        "26/05 - Game 10",
        "02/06 - Game 11",
        "09/06 - Game 12",
        "16/06 - Game 13",
        "23/06 - Game 14"
     ]

    constructor() {
        makeAutoObservable(this)
    }


    updateHeader(Title, Body, LoginID) {
        if (!this.APIRunning) {
            this.APIRunning = true
            var data = JSON.stringify({ "Title": Title, "Body": Body, "LoginID": LoginID });
            axios({
                method: 'post',
                url: this.API_BASE_URL + 'setHeaderData',
                headers: { 'Content-Type': 'application/json','Authorization': 'Bearer ' + profileStore.token},
                data: data
            })
                .then((returnedData) => {
                    if (returnedData.status === 200) {
                        this.HeaderTitle = Title
                        this.HeaderBody = Body
                        this.HeaderHidden = false
                        this.APIRunning = false
                    }
                })
                .catch(function (error){
                    if (error.response.status === 401) {
                      profileStore.resetStore()
                      //console.log(error.response.data); //Your token has expired please login again
                      //console.log(error.response.status); //401
                    }
                    else{
                        this.HeaderHidden = true
                        this.APIRunning = false
                    }
                  })
        }
    }

    getHeader() {
        axios({
            method: 'get',
            url: this.API_BASE_URL + '/getConfigs'
        })
            .then((returnedData) => {
                if (returnedData.status === 200) {
                    for (let i = 0; i < returnedData.data.length; i++) {
                        if (returnedData.data[i].ConfigName === "SecondaryHeaderTitle") {
                            this.HeaderTitle = returnedData.data[i].ConfigValue
                            this.HeaderHidden = false
                        }
                        else if (returnedData.data[i].ConfigName === "SecondaryHeaderValue") {
                            this.HeaderBody = returnedData.data[i].ConfigValue
                            this.HeaderHidden = false
                        }
                    }
                }
            })
            .catch(() => {
                this.HeaderHidden = true
            })
    }

    updateHeaderHidden() {
        if (this.HeaderHidden) {
            this.HeaderHidden = false
        } else {
            this.HeaderHidden = true
        }
    }
}
const configStore = new ConfigStore()
export default configStore
