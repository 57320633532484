import React from 'react';
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import { OverlayTrigger, Popover } from 'react-bootstrap';
import AboutUs from '../AboutUs/AboutUs';
import FeedbackForm from '../Feedback/FeedbackForm'

import './Footer.css'

function Footer() {

    const Developer_ProfilePop = (
        <Popover id="popoverDev" >
            <AboutUs />
        </Popover>
    );

    const FeedbackPop = (
        <Popover id="popoverDev" >
            <FeedbackForm />
        </Popover>
    );

    return (
        <Navbar collapseOnSelect id='footer' expand='lg' bg="light" variant="light" fixed='bottom'>

            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="mr-auto">

                    <Nav.Link className="FooterButton" href='https://gcha.altiusrt.com/' target="_blank">GC Hockey Draw</Nav.Link>

                </Nav>

                <Nav>
                    <OverlayTrigger trigger='click' rootClose overlay={FeedbackPop}>
                        <Nav.Link className="FooterButton">Feedback</Nav.Link>
                    </OverlayTrigger>

                    <OverlayTrigger trigger='click' rootClose overlay={Developer_ProfilePop}>
                        <Nav.Link className="FooterButton">About</Nav.Link>
                    </OverlayTrigger>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}
export default Footer;