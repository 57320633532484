import React from 'react';
import './UsersForm.css';
import { Card, Button, Nav, Form } from 'react-bootstrap'
import IssueFineForm from '../IssueFine/IssueFine'
import Chat from '../Chat/Chat';
import { observer, inject } from 'mobx-react';
import { BarChart, Bar, Legend, XAxis, YAxis,  Tooltip, ResponsiveContainer } from 'recharts';

class UsersForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            NavBarSelected: "Issue Fines",
            issueFineHidden: true,
            issueToLoginID: 0,
            issueTofirstname: "",
            issueTolastname: "",
            SendVotesFlag: false,
            Votes: 1,
            GameRound: 0,
            LoginID: '',
            firstname: '',
            lastname: '',
            Round: "10/03 - Game 1"
        };
    };

    componentDidMount(){
        this.props.notificationStore.askForApproval()
        this.props.finesStore.all(this.props.profileStore.loginID)
        this.getVotes()
        this.getvotesInterval = setInterval(() => { this.getVotes() }, 30 * 1000)
        this.mainInterval = setInterval(() => { this.props.finesStore.all(this.props.profileStore.loginID) }, 60 * 1000)
    }

    componentWillUnmount() {
        clearInterval(this.getvotesInterval)
        clearInterval(this.mainInterval)
      }

    handleFinesChange = (e) => {
        this.props.finesStore.updateSelectedTeam(e.target.value)
        this.getVotes()
        this.setState({ SendVotesFlag: false, LoginID: '', firstname: '', lastname: '' })
    }

    handleSummary() {
        let temp = []

        for (let i = 0; i < this.props.finesStore.finesTeamsSummary.length; i++) {
            temp.push(
                <tr>
                    <td>{this.props.finesStore.finesTeamsSummary[i].firstname} {this.props.finesStore.finesTeamsSummary[i].lastname}</td>
                    <td>${this.props.finesStore.finesTeamsSummary[i].ApprovedAmount}</td>
                    <td>${this.props.finesStore.finesTeamsSummary[i].PaidAmount}</td>
                </tr>)
        }
        return temp
    }

    hanldeNavBar = (e) => {
        const { text } = e.target
        this.setState({ 'NavBarSelected': text, 'returneddataset': [], 'lastRefrestTime': null })
    }

    returnteams() {
        let temp = []
        for (let i = 0; i < this.props.configStore.TeamsList.length; i++) {
            temp.push(<option>{this.props.configStore.TeamsList[i]}</option>)
        }
        return temp
    }

    handleChange = (e) => {
        const { id, value } = e.target
        this.setState(prevState => ({
            ...prevState,
            [id]: value
        }))
    }

    handleChangeRound = (e) => {
        const { id, value } = e.target
        this.setState(prevState => ({
            ...prevState,
            [id]: value
        }))
        this.getVotes(value)
        this.setState({ SendVotesFlag: false, LoginID: '', firstname: '', lastname: '' })
    }

    returnrounds() {
        let temp = []
        for (let i = 0; i < this.props.configStore.rounds.length; i++) {
            temp.push(<option>{this.props.configStore.rounds[i]}</option>)
        }
        return temp
    }

    CustomTooltip = ({ active, payload, label }) => {
        if (active) {
            return (
            <Card id='graphCard'>
              <p className="label">{payload[0].payload.firstname} {payload[0].payload.lastname}</p>
              <p className="label">Approved: ${payload[0].payload.ApprovedAmount}</p>
              <p className="label">Paid: ${payload[0].payload.PaidAmount}</p>
            </Card>
          );
        }
      
        return null;
      };

      CustomTooltipPOD = ({ active, payload, label }) => {
        if (active) {
            return (
            <Card id='graphCard'>
              <p className="label">{payload[0].payload.firstname} {payload[0].payload.lastname}</p>
              <p className="label">Team Votes: {payload[0].payload.Votes}</p>
              <p className="label">Supporter Votes: {payload[0].payload.SupporterVotes}</p>
            </Card>
          );
        }
        return null;
      };

    HandleContent = () => {
        if (this.state.NavBarSelected === "Issue Fines") {
            if (this.state.issueFineHidden) {
                return (
                    <div>
                        <Card id='mainUsersCard' size='small'>
                            <Card.Header id='usersHeader' as="h5">{this.state.NavBarSelected}:</Card.Header>
                            <Card.Body id='mainUsersbody'>

                                <Card.Title>Please select the players team.</Card.Title>

                                <div className="form-group">
                                    <select className="form-control" value={this.props.finesStore.SelectedTeam} onChange={(e) => this.handleFinesChange(e)}>
                                        {this.returnteams()}
                                    </select>
                                </div>

                                <Card.Text style={{marginTop:'1rem'}}>Once you issue a fine it will still need to be approved by the fine master in a fines meeting.</Card.Text>

                                <div className='table-responsive' hidden={!this.props.finesStore.getTeamUsersValid()}>
                                    <table className="table table-hover table-borderless">
                                        <thead>
                                            <tr>
                                                <th scope='col' hidden='true'>User</th>
                                                <th scope='col'>Players Name</th>
                                                <th scope='col'></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.handleFineUsers()}
                                        </tbody>
                                    </table>
                                </div>

                                <Card.Title hidden={!this.props.finesStore.getTeamUsersValid()} id="Userlabels1">Team's Summary:</Card.Title>

                                <div className='table-responsive' hidden={!this.props.finesStore.getTeamUsersValid()}>
                                    <table className="table table-borderless">
                                        <thead>
                                            <tr>
                                                <th style={{width:'50%'}} scope='col'>Team's Total Approved:</th>
                                                <th style={{width:'50%'}} scope='col'>Team's Total Paid:</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>${this.props.finesStore.finesTeamTotal}</td>
                                                <td>${this.props.finesStore.finesTeamPaid}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div className='table-responsive' hidden={!this.props.finesStore.getTeamUsersValid() || !this.props.finesStore.getfinesTeamsSummaryValid() ? true : false} style={{padding:'10px'}}>
                                    <ResponsiveContainer width={"180%"} height={320} minWidth={350}>
                                        <BarChart className='MyGraph' data={this.props.finesStore.finesTeamsSummary} margin={{top: 30, right: 5, left: 5}}>
                                            <XAxis dataKey="firstname" angle={-50} tickMargin={'15'}/>
                                            <YAxis type='number' angle={-30}/>
                                            <Tooltip content={this.CustomTooltip} />
                                            <Legend wrapperStyle={{top:0,left: 25}}/>
                                            <Bar dataKey="ApprovedAmount" text="test" fill="rgb(241,131,52)" label={{ position: "top" }}/>
                                            <Bar dataKey="PaidAmount" fill="rgb(58,188,224)" label={{ position: "top" }}/>
                                        </BarChart>
                                    </ResponsiveContainer>
                                </div>

                                <div className='table-responsive' hidden={!this.props.finesStore.getTeamUsersValid()}>
                                    <table className="table table-hover table-borderless">
                                        <thead>
                                            <tr>
                                                <th scope='col'>Name</th>
                                                <th scope='col'>Approved Amount</th>
                                                <th scope='col'>Paid Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.handleSummary()}
                                        </tbody>
                                    </table>
                                </div>

                                <Form.Group hidden={this.props.finesStore.getTeamUsersValid()}>
                                    <Form.Label id="warningLabel">There are no registered players in this team. Please try a different team.</Form.Label>
                                </Form.Group>
                            </Card.Body>
                        </Card>
                        <Card id='mainUsersCard' size='small'>
                            <Card.Header id='usersHeader' as="h5">Personal Fines Summary:</Card.Header>
                            <Card.Body id='mainUsersbody'>

                                <div className='table-responsive' hidden={!this.props.finesStore.getUsersFinesValid()}>
                                    <table className="table table-borderless">
                                        <thead className="thead-light">
                                            <tr>
                                                <th scope='col'>Total Fines</th>
                                                <th scope='col'>Approved Fines</th>
                                                <th scope='col'>Amount Paid</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>${this.props.finesStore.usersFineSummary.TotalAmount}</td>
                                                <td>${this.props.finesStore.usersFineSummary.ApprovedSum}</td>
                                                <td>${this.props.finesStore.usersFineSummary.PaidSum}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div className='table-responsive' hidden={!this.props.finesStore.getUsersFinesValid()}>
                                    <table className="table table-hover table-borderless">
                                        <thead>
                                            <tr>
                                                <th scope='col'>Fine ID</th>
                                                <th scope='col'>Description</th>
                                                <th scope='col'>Team</th>
                                                <th scope='col'>Amount</th>
                                                <th scope='col'>Approved</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.handleUsersFines()}
                                        </tbody>
                                    </table>
                                </div>

                                <Form.Group hidden={this.props.finesStore.getUsersFinesValid()}>
                                    <Form.Label id="GoodLabel">You dont have any fines yet. They will show here as soon as someone issues you one.</Form.Label>
                                </Form.Group>
                            </Card.Body>
                        </Card>
                    </div>

                )
            } else if (!this.state.issueFineHidden) {
                return (
                    <div>
                        <IssueFineForm issueToLoginID={this.state.issueToLoginID} issueTofirstname={this.state.issueTofirstname} issueTolastname={this.state.issueTolastname} team={this.props.finesStore.SelectedTeam}/>
                        <Button style={{ marginTop: '1rem' }} variant="danger" onClick={() => this.finishIssuingFine()}>Back</Button>
                    </div>
                )
            }
        }
        else if (this.state.NavBarSelected === "Chat") {
            return (<Chat />)
        }
        else if (this.state.NavBarSelected === "Player of the day") {
            return (
                <Card id='mainUsersCard' size='small'>
                    <Card.Header id='usersHeader' as="h5">{this.state.NavBarSelected}:</Card.Header>
                    <Card.Body id='mainUsersbody'>
                        
                        <Card.Title>Team:</Card.Title>

                        <div className="form-group">
                            <select className="form-control" value={this.props.finesStore.SelectedTeam} onChange={(e) => this.handleFinesChange(e)}>
                                {this.returnteams()}
                            </select>
                        </div>

                        <Card.Title>Round:</Card.Title>

                        <div className="form-group">
                            <select className="form-control" id='Round' value={this.state.Round} onChange={(e) => { this.handleChangeRound(e) }}>
                                {this.returnrounds()}
                            </select>
                        </div>

                        <Card.Title id="AdminLabel1">Send votes:</Card.Title>
                        <Card.Text>You get a total of 3 votes per round. These dont have to all go to one player.</Card.Text>

                        {this.sendvotesCard()}

                        <div className='table-responsive' hidden={this.props.finesStore.finesUsers.length === 0 ? true:false}>
                            <table className="table table-hover table-borderless">
                                <thead>
                                    <tr>
                                        <th scope='col' hidden='true'>User</th>
                                        <th scope='col'>Players Name</th>
                                        <th scope='col'></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.handleFineUsersPOD()}
                                </tbody>
                            </table>
                        </div>
                        <Form.Label hidden={!(this.props.finesStore.finesUsers.length === 0 ? true:false)} id='noVotesLabel'>There is currently no players assigned to this team...</Form.Label>
                        <Card.Title hidden={this.props.votesStore.votesData.length === 0 ? true : false}>This round's player of the day:</Card.Title>
                        <Card.Text>{this.state.Round}</Card.Text>

                        <div className='table-responsive' hidden={this.props.votesStore.votesData.length === 0 ? true : false} style={{padding:'10px'}}>
                                <ResponsiveContainer width={"100%"} height={300} minWidth={350}>
                                    <BarChart className='MyGraph' data={this.props.votesStore.votesData} margin={{top: 30, right: 5, left: 5}}>
                                    <XAxis dataKey="firstname" angle={-50} tickMargin={'15'}/>
                                        <YAxis angle={-30}/>
                                        <Legend/>
                                        <Tooltip content={this.CustomTooltipPOD} />
                                        <Bar dataKey="Votes" fill="rgb(241,131,52)" label={{ position: "top" }}/>
                                        <Bar dataKey="SupporterVotes" fill="rgb(58,188,224)" label={{ position: "top" }}/>
                                    </BarChart>
                                </ResponsiveContainer>
                            </div>

                        <div className='table-responsive' hidden={this.props.votesStore.votesData.length === 0 ? true : false}>
                            <table className="table table-hover table-borderless">
                                <thead>
                                    <tr>
                                        <th scope='col'>Players Name</th>
                                        <th scope='col'>Team Votes</th>
                                        <th scope='col'>Supporter Votes</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.handleUsersPODSummary()}
                                </tbody>
                            </table>
                        </div>
                        <Form.Label hidden={!(this.props.votesStore.votesData.length === 0 ? true : false)} id='noVotesLabel'>There is currently no votes to show... They will automatically populate as they come through.</Form.Label>
                    </Card.Body>
                </Card>
            )
        }
        else if (this.state.NavBarSelected === "Other") {
            return "Other section is still a work in progress"
        }
    }

    openIssueFine(issueToLoginID, issueTofirstname, issueTolastname) {
        this.setState({ 'issueFineHidden': false, 'issueToLoginID': issueToLoginID, 'issueTofirstname': issueTofirstname, 'issueTolastname': issueTolastname })
    }

    finishIssuingFine() {
        this.setState({ 'issueFineHidden': true, 'issueToLoginID': 0, 'issueTofirstname': "", 'issueTolastname': "", 'finesRun': false, 'usersFinesRun': false })
    }

    handleUsersFines() {
        let temp = []
        for (let i = 0; i < this.props.finesStore.usersFines.length; i++) {
            temp.push(
                <tr>
                    <td>{this.props.finesStore.usersFines[i].fineID}</td>
                    <td>{this.props.finesStore.usersFines[i].fineDescription}</td>
                    <td>{this.props.finesStore.usersFines[i].issuedToTeam}</td>
                    <td>{this.props.finesStore.usersFines[i].fineAmount}</td>
                    <td>{this.props.finesStore.usersFines[i].approved ? 'Yes' : 'No'}</td>
                </tr>)
        }
        return temp
    }

    handleFineUsersPOD() {
        let temp = []
        for (let i = 0; i < this.props.finesStore.finesUsers.length; i++) {
            temp.push(
                <tr>
                    <td hidden='true'>{this.props.finesStore.finesUsers[i].LoginID}</td>
                    <td>{this.props.finesStore.finesUsers[i].firstname + ' ' + this.props.finesStore.finesUsers[i].lastname}</td>
                    <td>
                        <Button id='IssuefineBtn' variant='outline-success' onClick={() => { this.setState({ SendVotesFlag: true, firstname: this.props.finesStore.finesUsers[i].firstname, lastname: this.props.finesStore.finesUsers[i].lastname, LoginID: this.props.finesStore.finesUsers[i].LoginID }) }}>Send Votes</Button>
                    </td>
                </tr>
            )
        }
        return temp
    }

    handleUsersPODSummary() {
        let temp = []
        for (let i = 0; i < this.props.votesStore.votesData.length; i++) {
            temp.push(
                <tr>
                    <td>{this.props.votesStore.votesData[i].firstname + ' ' + this.props.votesStore.votesData[i].lastname}</td>
                    <td>{this.props.votesStore.votesData[i].Votes}</td>
                    <td>{this.props.votesStore.votesData[i].SupporterVotes}</td>
                </tr>
            )
        }
        return temp
    }

    getVotes(round) {
        if (!round) {round = this.state.Round}
        this.props.votesStore.GetVotes(round, this.props.finesStore.SelectedTeam)
    }

    sendvotesCard() {
        return (
            <Card id='SendVotesCard' size='small' hidden={!this.state.SendVotesFlag}>
                <Card.Header id='SendVotesHeader' as="h5">Send Votes:</Card.Header>
                <Card.Body id="SendVotesBody">
                    Send votes to: {this.state.firstname} {this.state.lastname}
                    <Form>
                        <Form.Group>
                            <Card.Subtitle style={{ marginTop: '1rem' }}>Number of Votes:</Card.Subtitle>
                            <div className="form-group">
                                <select className="form-control" id='Votes' value={this.Votes} onChange={(e) => { this.handleChange(e) }}>
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                </select>
                            </div>

                            <Button variant="primary" style={{width:'33%'}} onClick={() => { this.props.votesStore.sendVotes(this.state.LoginID, this.props.profileStore.loginID, this.state.Votes, this.state.Round, this.props.finesStore.SelectedTeam) }}>Send Votes</Button>
                        </Form.Group>
                        <Button variant="outline-danger" style={{width:'33%'}} onClick={() => {this.setState({ SendVotesFlag: false, LoginID: '', firstname: '', lastname: '' }) }}>Back</Button>
                    </Form>

                    <div className="alert alert-success mt-2" role="alert" hidden={this.props.votesStore.thanksHidden()}>
                        <p>{this.props.votesStore.ThanksMsg}</p>
                    </div>

                    <div className="alert alert-danger mt-2" role="alert" hidden={this.props.votesStore.errorhidden()}>
                        <p>{this.props.votesStore.errorMsg}</p>
                    </div>
                </Card.Body>
            </Card>
        )
    }

    handleFineUsers() {
        let temp = []

        for (let i = 0; i < this.props.finesStore.finesUsers.length; i++) {
            temp.push(
                <tr>
                    <td hidden='true'>{this.props.finesStore.finesUsers[i].LoginID}</td>
                    <td>{this.props.finesStore.finesUsers[i].firstname + ' ' + this.props.finesStore.finesUsers[i].lastname}</td>
                    <td><Button id='IssuefineBtn' variant='outline-success' onClick={() => { this.openIssueFine(this.props.finesStore.finesUsers[i].LoginID, this.props.finesStore.finesUsers[i].firstname, this.props.finesStore.finesUsers[i].lastname) }}>Issue Fine</Button></td>
                </tr>)
        }
        return temp
    }

    render() {

        return (
            <div>
                <Nav id="MenuUsers" variant='tabs' className="justify-content-center" activeKey="/home">
                    <Nav.Item>
                        <Nav.Link id='MenuUsersItem' onClick={this.hanldeNavBar}>Issue Fines</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link id='MenuUsersItem' onClick={this.hanldeNavBar}>Player of the day</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link id='MenuUsersItem' onClick={this.hanldeNavBar}>Chat</Nav.Link>
                    </Nav.Item>
                </Nav>
                {this.HandleContent()}
            </div>
        )
    }
}
export default inject('finesStore', 'profileStore', 'notificationStore', 'configStore', 'votesStore')(observer(UsersForm))