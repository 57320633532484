import React from 'react';

import { Card, Button, Form } from 'react-bootstrap'
import './Chat.css'
import { observer, inject } from 'mobx-react';

class Chat extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      MessageToSend: '',
      team: 'Mens DIV1'
    };
    
    this.props.chatStore.getChatMessages();
  };

  componentDidMount() {
    this.timer = setInterval(() => {
      this.props.chatStore.getChatMessages();
    }, 10000);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  handleChange = (e) => {
    const { id, value } = e.target
    this.setState(prevState => ({
      ...prevState,
      [id]: value
    }))
  }

  SendMessage() {
    this.props.chatStore.SendMessage(this.state.MessageToSend, this.state.team, this.props.profileStore.loginID);
    this.setState({MessageToSend:''})
  }

  renderMessages() {
    let temp = []
    for (let i = 0; i < this.props.chatStore.Messages.length; i++) {
      if (Number(this.props.profileStore.loginID) === Number(this.props.chatStore.Messages[i].loginID)) {
        temp.push(
          <Card id='MyMessageCard'>
            <Card.Body>
              <Card.Subtitle className="mb-2">You</Card.Subtitle>
              <Card.Text>{this.props.chatStore.Messages[i].message}</Card.Text>
            </Card.Body>
          </Card>
        )
      }
      else {
        temp.push(

          <Card id='MessageCard'>
            <Card.Body>
              <Card.Subtitle className="mb-2">{this.props.chatStore.Messages[i].firstname} {this.props.chatStore.Messages[i].lastname}</Card.Subtitle>
              <Card.Text>{this.props.chatStore.Messages[i].message}</Card.Text>
            </Card.Body>
          </Card>
        )
      }
    }
    return temp
  }

  render() {
    return (
      <Card id='CardChatMain' size='small'>
        <Card.Header as="h4" style={{ textAlign: 'left' }}>Team Chat:</Card.Header>
        <Card.Body id='CardChatBody'>
          {this.renderMessages()}
        </Card.Body>
        <Card.Footer id='CardChatBody'>
          <Form style={{textAlign:'end',alignItems:'center'}}>
            <Form.Group>
              <textarea className="form-control" id="MessageToSend" placeholder="Message..." onChange={this.handleChange} value={this.state.MessageToSend}></textarea>
              <Button id='BtnSendMessage' onClick={() => this.SendMessage()}>Send</Button>
            </Form.Group>
          </Form>

        </Card.Footer>
      </Card>
    );
  }
}
export default inject('configStore', 'chatStore', 'profileStore')(observer(Chat))