import React,{ Component } from 'react';
import Footer from './components/Footer/Footer'
import finesStore from './Stores/FinesStore';
import profileStore from './Stores/ProfileStore';
import votesStore from './Stores/VotesStore'
import {Switch,Route} from "react-router-dom";
import { Provider } from 'mobx-react';
import Home from './Pages/Home/Home'
import RegistrationPage from './Pages/RegistrationPage/RegistrationPage'
import LoggedinPage from './Pages/LoggedinPage/LoggedinPage'
import notificationStore from './Stores/NotificationStore';
import configStore from './Stores/ConfigStore';
import chatStore from './Stores/ChatStore';

export default class App extends Component {

  Page_Not_Found() {
    return (
    <div style={{marginTop:'4rem'}}>
      <h3 style={{textAlign:'center',color:'white'}}>Hmm... The page you were looking for cannot be found</h3> <br/>
      <p style={{textAlign:'center',color:'white'}}>Please try going <u><a href="/">Home</a></u></p>)
    </div>)
  }

  Page_Looking_For_Funding() {
    return (
    <div style={{marginTop:'4rem'}}>
      <h3 style={{textAlign:'center',color:'white'}}>The page you are trying to access is looking for funding.</h3> <br/>
      <p style={{textAlign:'center',color:'white'}}>Please contact Chad</p>)
    </div>)
  }

//npm install axios
//npm install react-router-dom
//npm install react-router
  render(){

    return (
      <Provider
        profileStore={profileStore}
        finesStore={finesStore}
        notificationStore={notificationStore}
        configStore={configStore}
        votesStore={votesStore}
        chatStore={chatStore}
      >
          <Switch> {/* The Switch decides which component to show based on the current URL.*/}
              <Route exact path='/' component={Home}></Route>
              <Route exact path='/Registration' component={RegistrationPage}></Route>
              <Route exact path='/LoggedIn' component={LoggedinPage}></Route>
              <Route path='' component={this.Page_Not_Found}></Route>
          </Switch>

          <Footer/>
      </Provider>
    )  
  }
}