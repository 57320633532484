import { makeAutoObservable } from "mobx"
import axios from "axios"
import configStore from "./ConfigStore";
import profileStore from "./ProfileStore";

class VotesStore {
    votesData = []
    errorMsg = ""
    ThanksMsg = ""
    votesSeasonData = []

    constructor() {
        makeAutoObservable(this)
    }

    errorhidden() {
        return this.errorMsg.length > 0 ? false : true
    }

    thanksHidden() {
        return this.ThanksMsg.length > 0 ? false : true
    }

    sendVotes(ToLoginID, FromLoginID, Votes, Round, SelectedTeam) {
        this.errorMsg = ""
        var data = JSON.stringify({ "IssueToLoginID": ToLoginID, "IssuedByLoginID": FromLoginID, "Votes": Votes, "GameRound": Round, "Team": SelectedTeam });
        axios({
            method: 'post',
            url: configStore.API_BASE_URL + 'SendVotes',
            headers: { 'Content-Type': 'application/json','Authorization': 'Bearer ' + profileStore.token},
            data: data
        })
            .then((returnedData) => {
                if (returnedData.status === 200) {
                    if (returnedData.data.VoteCount[0] > 3) {
                        this.errorMsg = 'You are trying to submit too many votes. You get a max of 3 per round.'
                        this.ThanksMsg = ""
                    }
                    else {
                        this.errorMsg = ""
                        this.ThanksMsg = "Your votes have been submitted..."
                        this.GetVotes(Round,SelectedTeam)
                        this.GetSeasonVotes(SelectedTeam)
                    }
                }
                else {
                    this.errorMsg = "Unable to submit your votes... Please try again."
                    this.ThanksMsg = ""
                }
            })
            .catch(function (error){
                if (error.response.status === 401) {
                  profileStore.resetStore()
                  //console.log(error.response.data); //Your token has expired please login again
                  //console.log(error.response.status); //401
                }
                else{
                    this.errorMsg = "Unable to submit your votes... Please try again."
                }
            })
    }

    GetVotes(round, selectedTeam) {
        var data = JSON.stringify({ "Team": selectedTeam, "GameRound": round });
        axios({
            method: 'post',
            url: configStore.API_BASE_URL + 'getVotes',
            headers: { 'Content-Type': 'application/json','Authorization': 'Bearer ' + profileStore.token},
            data: data
        })
            .then((returnedData) => {
                this.votesData = returnedData.status === 200 ? returnedData.data : []
            })
            .catch(function (error){
                if (error.response.status === 401) {
                  profileStore.resetStore()
                  //console.log(error.response.data); //Your token has expired please login again
                  //console.log(error.response.status); //401
                }
                else{
                    this.votesData = []
                }
            })
    }

    GetSeasonVotes(selectedTeam) {
        var data = JSON.stringify({ "Team": selectedTeam, "GameRound": 'ALL' });
        axios({
            method: 'post',
            url: configStore.API_BASE_URL + 'getVotes',
            headers: { 'Content-Type': 'application/json','Authorization': 'Bearer ' + profileStore.token},
            data: data
        })
            .then((returnedData) => {
                this.votesSeasonData = returnedData.status === 200 ? returnedData.data : []
            })
            .catch(function (error){
                if (error.response.status === 401) {
                  profileStore.resetStore()
                  //console.log(error.response.data); //Your token has expired please login again
                  //console.log(error.response.status); //401
                }
                else{
                    this.votesSeasonData = []
                }
            })
    }
}
const votesStore = new VotesStore()
export default votesStore