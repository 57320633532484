import React from 'react';
import './FinesManagement.css';
import { Card, Button, Form } from 'react-bootstrap'
import { observer, inject } from 'mobx-react';
import FinesPaid from '../FinesPaid/FinesPaid';

class FinesManagement extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };

        this.props.finesStore.getallfines()
        this.props.finesStore.getTeamFinesSummary()
        this.props.finesStore.getAllApprovedFines()
    };

    handleAmountChange(e, id) {
        this.props.finesStore.finesData[id].fineAmount = e.target.value
    }

    handleSummary() {
        let temp = []
        for (let i = 0; i < this.props.finesStore.finesTeamsSummary.length; i++) {
            temp.push(
                <tr>
                    <td>{this.props.finesStore.finesTeamsSummary[i].firstname} {this.props.finesStore.finesTeamsSummary[i].lastname}</td>
                    <td>${this.props.finesStore.finesTeamsSummary[i].ApprovedAmount}</td>
                    <td>${this.props.finesStore.finesTeamsSummary[i].PaidAmount}</td>
                </tr>)
        }
        return temp
    }

    handleAllApprovedFines(){
        let temp = []
        for (let i = 0; i < this.props.finesStore.AllApprovedFines.length; i++) {
            temp.push(
                <tr>
                    <td>{this.props.finesStore.AllApprovedFines[i].IssuedTofirstname} {this.props.finesStore.AllApprovedFines[i].IssuedTolastname}</td>
                    <td>${this.props.finesStore.AllApprovedFines[i].fineAmount}</td>
                    <td>{this.props.finesStore.AllApprovedFines[i].fineDescription}</td>
                    <td>{this.props.finesStore.AllApprovedFines[i].IssuedByfirstname} {this.props.finesStore.AllApprovedFines[i].IssuedBylastname}</td>
                </tr>)
        }
        return temp
    }

    handleFines() {
        let temp = []
        for (let i = 0; i < this.props.finesStore.finesData.length; i++) {
            temp.push(
                <tr>
                    <td>{this.props.finesStore.finesData[i].fineID}</td>
                    <td>{this.props.finesStore.finesData[i].fineDescription}</td>
                    <td><Form.Control placeholder="0.00" value={this.props.finesStore.finesData[i].fineAmount} onChange={(e) => this.handleAmountChange(e, i)} /></td>
                    <td>{this.props.finesStore.finesData[i].IssuedTofirstname + ' ' + this.props.finesStore.finesData[i].IssuedTolastname}</td>
                    <td>{this.props.finesStore.finesData[i].IssuedByfirstname + ' ' + this.props.finesStore.finesData[i].IssuedBylastname}</td>
                    <td><Button variant='success' onClick={() => this.props.finesStore.actionFines(this.props.finesStore.finesData[i].fineID, true, this.props.finesStore.finesData[i].fineAmount)}>Approve</Button>
                        <Button variant='danger' onClick={() => this.props.finesStore.actionFines(this.props.finesStore.finesData[i].fineID, false, this.props.finesStore.finesData[i].fineAmount)}>Decline </Button>
                    </td>
                </tr>)
        }
        return temp
    }

    handleFinesChange = (e) => {
        this.props.finesStore.updateSelectedTeam(e.target.value)
        this.props.finesStore.getallfines()
        this.props.finesStore.getTeamFinesSummary()
    }

    showtable() {
        if (this.props.finesStore.finesData.length === 0) {
            return true
        }
        else { return false }
    }

    returnteams() {
        let temp = []
        for (let i = 0; i < this.props.configStore.TeamsList.length; i++) {
            temp.push(
                <option>{this.props.configStore.TeamsList[i]}</option>
            )
        }
        return temp
    }

    render() {
        return (
            <div>
                <Card id='mainAdminCard' size='small'>
                    <Card.Header id="AdminHeader" as="h5" style={{ textAlign: 'left' }}>Team:</Card.Header>
                    <Card.Body id='mainAdminsbody'>
                        <Card.Title>Please select the team:</Card.Title>

                        <div className="form-group">
                            <select className="form-control" value={this.props.finesStore.SelectedTeam} onChange={(e) => this.handleFinesChange(e)}>
                                {this.returnteams()}
                            </select>
                        </div>

                    </Card.Body>
                </Card>

                <Card id='mainAdminCard' size='small'>
                    <Card.Header id="AdminHeader" as="h5" style={{ textAlign: 'left' }}>Outstanding Fines:</Card.Header>
                    <Card.Body id='mainAdminsbody'>
                        <Card.Text hidden={this.showtable()}>Please approve or decline each outstanding fine. Once approved you cannot go back and delete it. You can edit the amount if needed.</Card.Text>
                        <Card.Text hidden={!this.showtable()}>There are no outstanding fines for this team. Once someone issues a fine you will be able to approve or decline it here.</Card.Text>

                        <div className='table-responsive'>
                            <table className="table table-hover table-borderless" hidden={this.showtable()}>
                                <thead>
                                    <tr>
                                        <th scope='col'>Fine ID</th>
                                        <th scope='col'>Description</th>
                                        <th scope='col'>Amount</th>
                                        <th scope='col'>Issued To</th>
                                        <th scope='col'>Issued By</th>
                                        <th scope='col'>Approve</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.handleFines()}
                                </tbody>
                            </table>
                        </div>
                    </Card.Body>
                </Card>

                <Card id='mainAdminCard' size='small'>
                    <Card.Header id="AdminHeader" as="h5" style={{ textAlign: 'left' }}>Fines Paid:</Card.Header>
                    <Card.Body id='mainAdminsbody'>
                        <FinesPaid />
                    </Card.Body>
                </Card>

                <Card id='mainAdminCard' size='small'>
                    <Card.Header id="AdminHeader" as="h5" style={{ textAlign: 'left' }}>Team's Overview:</Card.Header>
                    <Card.Body id='mainAdminsbody'>

                    <Card.Title>Team Summary:</Card.Title>

                        <div className='table-responsive'>
                            <table className="table table-hover table-borderless">
                                <thead>
                                    <tr>
                                        <th style={{width:'50%'}} scope='col'>Team's Total Approved:</th>
                                        <th style={{width:'50%'}} scope='col'>Team's Total Paid:</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>${this.props.finesStore.finesTeamTotal}</td>
                                        <td>${this.props.finesStore.finesTeamPaid}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <Card.Title style={{marginTop:'2rem'}}>Player Summary:</Card.Title>

                        <div className='table-responsive'>
                            <table className="table table-hover table-borderless">
                                <thead>
                                    <tr>
                                        <th scope='col'>Name</th>
                                        <th scope='col'>Approved Amount</th>
                                        <th scope='col'>Paid Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.handleSummary()}
                                </tbody>
                            </table>
                        </div>

                        <Card.Title style={{marginTop:'2rem'}}>Individual Fines:</Card.Title>
                        <Card.Text>This shows every fine that has been approved. Only admins get this overview of every fine. Players can only see the fines issued to them and the team summaries.</Card.Text>

                        <div className='table-responsive'>
                            <table className="table table-hover table-borderless">
                                <thead>
                                    <tr>
                                        <th scope='col'>Name</th>
                                        <th scope='col'>Amount</th>
                                        <th scope='col'>Description</th>
                                        <th scope='col'>Issued By</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {this.handleAllApprovedFines()}
                                </tbody>
                            </table>
                        </div>

                    </Card.Body>
                </Card>
            </div>
        )
    }
}
export default inject('finesStore', 'profileStore', 'configStore')(observer(FinesManagement))