import React from 'react';

import { Card, Button, Form } from 'react-bootstrap'
import axios from 'axios';
import './FeedbackForm.css'
import { observer, inject } from 'mobx-react';

class FeedbackForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      heading: '',
      description: '',
      hidethanks: true,
      errorhidden: true,
      feedbackid: 0
    };
  };

  SendFeedback() {

    if (this.state.heading && this.state.description) {
      var data = JSON.stringify({ "HeadingValue": this.state.heading, "DescriptionValue": this.state.description });
      axios({
        method: 'post',
        url: this.props.configStore.API_BASE_URL + 'Feedback',
        headers: {'Content-Type': 'application/json'},
        data: data
      })
        .then((returnedData) => {
          if (returnedData.status === 200) {
            this.setState({ 'hidethanks': false, 'errorhidden': true, 'feedbackid': returnedData.data.Feedback_ID })
          }
          else {
            this.setState({ 'hidethanks': true, 'errorhidden': false, 'feedbackid': 0 })
          }
        })
        .catch(() => {
          this.setState({ 'hidethanks': true, 'errorhidden': false, 'feedbackid': 0 })
        })

    } else {
      this.setState({ 'errorhidden': false, 'feedbackid': 0 })
    }
  }

  handleChangeHeading = (event) => {
    event.preventDefault();
    this.setState({ 'heading': event.target.value })
  }

  handleChangeDescription = (event) => {
    event.preventDefault();
    this.setState({ 'description': event.target.value })
  }

  render() {
    return (
      <Card id='FeedbackCard'>
        <Card.Header id="Header" as='h5'>Feedback:</Card.Header>
        <Card id='FeedbackCardSecondary'>
          <Card.Body id="FeedbackBody">
            Please provide any suggestions or feedback that you think could improve the way that we recognise our chooks!
            <Form>
              <Form.Group>
                <Card.Subtitle style={{ marginTop: '1rem' }}>Subject:</Card.Subtitle>

                <Form.Control id="heading" placeholder="Please enter a subject" onChange={this.handleChangeHeading} value={this.state.heading /*hhave to have it set as value and not in the element*/}></Form.Control>
                <Card.Subtitle style={{ marginTop: '1rem' }}>Description: </Card.Subtitle>
                <Form.Control id="description" placeholder="Please enter a description" onChange={this.handleChangeDescription} value={this.state.description}></Form.Control>

                <Button style={{ marginTop: '1rem' }} variant="primary" /*type="submit"*/ onClick={() => this.SendFeedback()} hidden={!this.state.hidethanks}>Submit</Button>
              </Form.Group>
            </Form>

            <div className="alert alert-success mt-2" role="alert" hidden={this.state.hidethanks}>
              <p>Thank you for taking the time to send us your feedback! Feedback Reference: {this.state.feedbackid}</p>
            </div>

            <div className="alert alert-danger mt-2" role="alert" hidden={this.state.errorhidden}>
              <p>Please enter some details before submitting.</p>
            </div>
          </Card.Body>
        </Card>
      </Card>
    );
  }
}
export default inject('configStore') (observer(FeedbackForm))