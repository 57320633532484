import { makeAutoObservable } from "mobx"
import axios from "axios"
import configStore from "./ConfigStore";
import profileStore from "./ProfileStore";

class FinesStore {
    finesUsers = []
    finesTeamTotal = 0
    finesTeamPaid = 0
    finesTeamsSummary = []
    SelectedTeam = 'Mens DIV1'
    usersFines = []
    usersFineSummary = { 'TotalAmount': 0, 'ApprovedSum': 0, 'PaidSum': 0 }
    finesData = []
    APICallInProgress = false
    AllApprovedFines = []
    AllUsers=[]//used to revoke peoples access

    constructor() {
        makeAutoObservable(this)
    }

    getTeamUsers() {
        var data = JSON.stringify({ "team": this.SelectedTeam });
        axios({
            method: 'post',
            url: configStore.API_BASE_URL + 'FinesUsers',
            headers: { 'Content-Type': 'application/json','Authorization': 'Bearer ' + profileStore.token},
            data: data
        })
            .then((returnedData) => {
                if (returnedData.status === 200) {
                    this.finesUsers = returnedData.data
                }
                else {
                    this.finesUsers = []
                }
            })
            .catch(function (error){
                if (error.response.status === 401) {
                  profileStore.resetStore()
                  //console.log(error.response.data); //Your token has expired please login again
                  //console.log(error.response.status); //401
                }
                else{
                    this.finesUsers = []
                }
            })
    }

    getAllUsers () {
        var data = JSON.stringify({ "team": 'ALL' });
        axios({
            method: 'post',
            url: configStore.API_BASE_URL + 'FinesUsers',
            headers: { 'Content-Type': 'application/json','Authorization': 'Bearer ' + profileStore.token},
            data: data
        })
            .then((returnedData) => {
                if (returnedData.status === 200) {
                    this.AllUsers = returnedData.data
                }
                else {
                    this.AllUsers = []
                }
            })
            .catch(function (error){
                if (error.response.status === 401) {
                  profileStore.resetStore()
                  //console.log(error.response.data); //Your token has expired please login again
                  //console.log(error.response.status); //401
                }
                else{
                    this.AllUsers = []
                }
            })
    }

    getTeamsTotal() {
        axios({
            method: 'get',
            url: configStore.API_BASE_URL + 'GetFinesSummary/4/' + this.SelectedTeam,
            headers: { 'Content-Type': 'application/json','Authorization': 'Bearer ' + profileStore.token},
        })
            .then((returnedData) => {
                if (returnedData.status === 200) {
                    this.finesTeamTotal = returnedData.data.TeamApprovedSum
                    this.finesTeamPaid = returnedData.data.TeamPaidAmount
                }
                else {
                    this.finesTeamTotal = 0
                    this.finesTeamPaid = 0
                }
            })
            .catch(function (error){
                if (error.response.status === 401) {
                  profileStore.resetStore()
                  //console.log(error.response.data); //Your token has expired please login again
                  //console.log(error.response.status); //401
                }
                else{
                    this.finesTeamTotal = 0
                    this.finesTeamPaid = 0
                }
            })
    }

    /*return if there are users in the team selected.*/
    getTeamUsersValid() {
        return this.finesUsers.length > 0
    }

    all(loginID) {
        if (loginID) {
            this.getTeamUsers()
            this.getUsersFines(loginID)
            this.getTeamFinesSummary()
            this.getallfines()
        }
        else {
            this.getTeamUsers()
            this.getTeamFinesSummary()
            this.getallfines()
        }
    }

    updateSelectedTeam(NewTeam, loginID) {
        this.SelectedTeam = NewTeam
        if (loginID) {
            this.getTeamUsers()
            this.getUsersFines(loginID)
            this.getTeamFinesSummary()
            this.getallfines()
        }
        else {
            this.getTeamUsers()
            this.getTeamFinesSummary()
            this.getallfines()
        }
    }

    getTeamFinesSummary() {
        axios({
            method: 'get',
            url: configStore.API_BASE_URL + 'GetFinesSummary/3/' + this.SelectedTeam,
            headers: { 'Content-Type': 'application/json','Authorization': 'Bearer ' + profileStore.token},
        })
            .then((returnedData) => {

                if (returnedData.status === 200) {
                    this.finesTeamsSummary = returnedData.data
                    this.getTeamsTotal(this.SelectedTeam)
                }
                else {
                    this.finesTeamsSummary = []
                    this.getTeamsTotal(this.SelectedTeam)
                }
            })
            .catch(function (error){
                if (error.response.status === 401) {
                  profileStore.resetStore()
                  //console.log(error.response.data); //Your token has expired please login again
                  //console.log(error.response.status); //401
                }
                else{
                    this.finesTeamsSummary = []
                    this.getTeamsTotal(this.SelectedTeam)
                }
            })
    }

    getAllApprovedFines() {
        axios({
            method: 'get',
            url: configStore.API_BASE_URL + 'AllApprovedFines/' + this.SelectedTeam,
            headers: { 'Content-Type': 'application/json','Authorization': 'Bearer ' + profileStore.token},
        })
            .then((returnedData) => {

                if (returnedData.status === 200) {
                    this.AllApprovedFines = returnedData.data
                }
                else {
                    this.AllApprovedFines = []
                }
            })
            .catch(function (error){
                if (error.response.status === 401) {
                  profileStore.resetStore()
                  //console.log(error.response.data); //Your token has expired please login again
                  //console.log(error.response.status); //401
                }
                else{
                    this.AllApprovedFines = []
                }
            })
    }

    getfinesTeamsSummaryValid() {
        return this.finesTeamsSummary.length > 0
    }

    getUsersFines(loginID) {
        var data = JSON.stringify({ "LoginID": loginID });
        axios({
            method: 'post',
            url: configStore.API_BASE_URL + 'GetFines',
            headers: { 'Content-Type': 'application/json',"Access-Control-Allow-Origin": "*",'Authorization': 'Bearer ' + profileStore.token},
            data: data
        })
            .then((returnedData) => {
                if (returnedData.status === 200) {
                    this.usersFines = returnedData.data
                    this.getUsersFinesSummary(loginID)
                }
                else {
                    this.usersFines = []
                }
            })
            .catch(function (error){
                if (error.response.status === 401) {
                  profileStore.resetStore()
                  //console.log(error.response.data); //Your token has expired please login again
                  //console.log(error.response.status); //401
                }
                else{
                    this.usersFines = []
                }
            })
    }

    getUsersFinesValid() {
        return this.usersFines.length > 0
    }

    getUsersFinesSummary(loginID) {
        axios({
            method: 'get',
            url: configStore.API_BASE_URL + 'GetFinesSummary/1/' + loginID,
            headers: { 'Content-Type': 'application/json','Authorization': 'Bearer ' + profileStore.token},
        })
            .then((returnedData) => {
                if (returnedData.status === 200) {
                    this.usersFineSummary = returnedData.data
                }
                else {
                    this.usersFineSummary = { 'TotalAmount': 0, 'ApprovedSum': 0, 'PaidSum': 0 }
                }
            })
            .catch(function (error){
                if (error.response.status === 401) {
                  profileStore.resetStore()
                  //console.log(error.response.data); //Your token has expired please login again
                  //console.log(error.response.status); //401
                }
                else{
                    this.usersFineSummary = { 'TotalAmount': 0, 'ApprovedSum': 0, 'PaidSum': 0 }
                }
            })
    }

    //All fines not approved
    getallfines() {
        axios({
            method: 'get',
            url: configStore.API_BASE_URL + 'GetFinesSummary/2/' + this.SelectedTeam,
            headers: { 'Content-Type': 'application/json','Authorization': 'Bearer ' + profileStore.token},
        })
            .then((returnedData) => {
                if (returnedData.status === 200) {
                    this.finesData = returnedData.data
                }
                else {
                    this.finesData = []
                }
            })
            .catch(function (error){
                if (error.response.status === 401) {
                  profileStore.resetStore()
                  //console.log(error.response.data); //Your token has expired please login again
                  //console.log(error.response.status); //401
                }
                else{
                    this.finesData = []
                }
            })
    }

    actionFines(fineID, approved, Amount) {
        if (!this.APICallInProgress) {
            this.APICallInProgress = true
            var data = JSON.stringify({ "fineID": fineID, "approved": approved, "amount": Amount });
            axios({
                method: 'post',
                url: configStore.API_BASE_URL + 'ActionFinesSummary',
                headers: { 'Content-Type': 'application/json','Authorization': 'Bearer ' + profileStore.token},
                data: data
            })
                .then((returnedData) => {
                    if (returnedData.status === 200) {
                        this.getTeamFinesSummary()
                        this.getallfines()
                        this.getAllApprovedFines()
                        this.APICallInProgress = false
                    }
                })
                .catch(function (error){
                    if (error.response.status === 401) {
                      profileStore.resetStore()
                      this.APICallInProgress = false
                      //console.log(error.response.data); //Your token has expired please login again
                      //console.log(error.response.status); //401
                    }
                    else{
                        this.APICallInProgress = false
                    }
                })
        }
    }
}
const finesStore = new FinesStore()
export default finesStore