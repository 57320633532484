import React from 'react';
import './PODSeasonSummary.css';
import { Card} from 'react-bootstrap'
import { observer, inject } from 'mobx-react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, Legend } from 'recharts';

class PODSeasonSummary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
        props.votesStore.GetSeasonVotes(this.props.finesStore.SelectedTeam)
    };

    handleUsersPODSummarySeason() {
        let temp = []
        for (let i = 0; i < this.props.votesStore.votesSeasonData.length; i++) {
            temp.push(
                <tr>
                    <td>{this.props.votesStore.votesSeasonData[i].firstname + ' ' + this.props.votesStore.votesSeasonData[i].lastname}</td>
                    <td>{this.props.votesStore.votesSeasonData[i].Votes}</td>
                    <td>{this.props.votesStore.votesSeasonData[i].SupporterVotes}</td>
                </tr>
            )
        }
        return temp
    }

    CustomTooltipPOD = ({ active, payload, label }) => {
        if (active) {
            return (
            <Card id='graphCard'>
              <p className="label">{payload[0].payload.firstname} {payload[0].payload.lastname}</p>
              <p className="label">Team Votes: {payload[0].payload.Votes}</p>
              <p className="label">Supporter Votes: {payload[0].payload.SupporterVotes}</p>
            </Card>
          );
        }
        return null;
      };

    render() {
        return (
                <Card id='mainAdminCard' size='small'>
                        <Card.Header id="AdminHeader" as="h5" style={{ textAlign: 'left' }}>Season Summary:</Card.Header>
                        <Card.Body id='mainAdminsbody'>
                            <Card.Text id="AdminLabel1">This shows who has the most votes for this team over the entire season</Card.Text>
                            <Card.Text id="AdminLabel1">Only administrators have this overview</Card.Text>

                            <div className='table-responsive' style={{padding:'10px'}} hidden={this.props.votesStore.votesSeasonData.length === 0 ? true : false}>
                                <ResponsiveContainer width={"100%"} height={300} minWidth={350}>
                                    <BarChart className='MyGraph' data={this.props.votesStore.votesSeasonData} margin={{top: 30, right: 5, left: 5}}>
                                        <XAxis dataKey="firstname" angle={-50} tickMargin={'15'}/>
                                        <YAxis angle={-30}/>
                                        <Tooltip content={this.CustomTooltipPOD} />
                                        <Legend wrapperStyle={{top:0,left: 25}}/>
                                        <Bar dataKey="Votes" fill="rgb(241,131,52)" label={{ position: "top" }}/>
                                        <Bar dataKey="SupporterVotes" fill="rgb(58,188,224)" label={{ position: "top" }}/>
                                    </BarChart>
                                </ResponsiveContainer>
                            </div>
                            
                            <div className='table-responsive'>
                                <table className="table table-hover table-borderless">
                                    <thead>
                                        <tr>
                                            <th scope='col'>Players Name</th>
                                            <th scope='col'>Team Votes</th>
                                            <th scope='col'>Supporter Votes</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.handleUsersPODSummarySeason()}
                                    </tbody>
                                </table>
                            </div>
                        </Card.Body>
                    </Card>
        )
    }
}
export default inject('finesStore', 'votesStore')(observer(PODSeasonSummary))