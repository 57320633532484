import React from 'react';
import './FinesPaid.css';
import { Button, Form } from 'react-bootstrap'
import { observer, inject } from 'mobx-react';
import axios from "axios"

class FinesPaid extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            PaidAmount: 0,
            selectedPlayer: this.props.finesStore.finesUsers[0].LoginID + " Default",
            errorshow: false,
            error: "",
            loading: false,
            successMessage: "Payment has been added",
            successShow: false
        };
    };

    handleChange = (e) => {
        const { id, value } = e.target
        this.setState(prevState => ({
            ...prevState,
            [id]: value
        }))
    }

    returnplayers() {
        let temp = []
        for (let i = 0; i < this.props.finesStore.finesUsers.length; i++) {
            temp.push(
                <option>{this.props.finesStore.finesUsers[i].LoginID} {this.props.finesStore.finesUsers[i].firstname} {this.props.finesStore.finesUsers[i].lastname}</option>
            )
        }
        return temp
    }

    sendRequest() {
        this.setState({ errorshow: false, loading: true, successShow: false })
        var mytemp = this.state.selectedPlayer.split(" ")
        if (Number(mytemp[0]) && this.state.PaidAmount && this.state.PaidAmount > 0) {

            var data = JSON.stringify({ "loginID": Number(mytemp[0]), "amount": this.state.PaidAmount, "submitterID": this.props.profileStore.loginID });
            axios({
                method: 'post',
                url: this.props.configStore.API_BASE_URL + 'FinePaid',
                headers: { 'Content-Type': 'application/json', "Access-Control-Allow-Origin": "*",'Authorization': 'Bearer ' + this.props.profileStore.token},
                data: data
            })
                .then((returnedData) => {
                    if (returnedData.status === 200) {
                        if (Number(mytemp[0]) === Number(this.props.profileStore.loginID)) {
                            this.setState({ errorshow: false, loading: false, successMessage: "I hope you actually did pay yourself. Regardless, its been added.", successShow: true, PaidAmount: 0 })
                        }
                        else {
                            this.setState({ errorshow: false, loading: false, successMessage: "Payment has been added", successShow: true, PaidAmount: 0 })
                        }
                    }
                    else {
                        this.setState({ error: 'That didnt Work... Please try again', errorshow: true, loading: false, successShow: false })
                    }
                })
                .catch(function (error){
                    if (error.response.status === 401) {
                        this.props.profileStore.resetStore()
                      //console.log(error.response.data); //Your token has expired please login again
                      //console.log(error.response.status); //401
                    }
                    else{
                        this.setState({ error: 'That didnt Work... Please try again', errorshow: true, loading: false, successShow: false })
                    }
                })
        }
        else {
            this.setState({ error: 'Please check your values and try again.', errorshow: true, loading: false, successShow: false })
        }
    }

    render() {
        return (
            <div>
                <Form>
                    <Form.Text style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}>Please select the player below who has paid some of their fines:</Form.Text>
                    <div className="form-group">
                        <select className="form-control" id="selectedPlayer" value={this.state.selectedPlayer} onChange={(e) => { this.handleChange(e) }} >
                            {this.returnplayers()}
                        </select>
                    </div>

                    <Form.Text style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}>Please enter the amount they have paid:</Form.Text>
                    <Form.Control id="PaidAmount" placeholder="0.00" type='money' onChange={this.handleChange} value={this.state.PaidAmount}></Form.Control>
                    <Button style={{ marginTop: '1rem', minWidth: '30%' }} variant="outline-success" onClick={() => this.sendRequest()}>Submit</Button>

                    <Form.Text style={{ marginTop: '1rem' }} id='loadingLabel' hidden={!this.state.loading}>Loading. . . .</Form.Text>

                    <div className="alert alert-success mt-2" role="alert" hidden={!this.state.successShow}>
                        {this.state.successMessage}
                    </div>

                    <div className="alert alert-danger mt-2" role="alert" hidden={!this.state.errorshow}>
                        {this.state.error}
                    </div>
                </Form>
            </div>
        )
    }
}

export default inject('finesStore', 'profileStore', 'configStore')(observer(FinesPaid))