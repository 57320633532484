import React from 'react';
import './AdminForm.css';
import { Card, Button, Nav, Form } from 'react-bootstrap'
import axios from 'axios';
import IssueFineForm from '../IssueFine/IssueFine'
import { observer, inject } from 'mobx-react';
import FinesManagement from './FinesManagement/FinesManagement'
import Chat from '../Chat/Chat';
import ConfigManagement from './ConfigManagement/ConfigManagement'
import PODSeasonSummary from '../PODSeasonSummary/PODSeasonSummary'
import { BarChart, Bar, XAxis, YAxis, Tooltip,  ResponsiveContainer, Legend} from 'recharts';

class AdminForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            NavBarSelected: "Fines",
            unauthedusers: [],
            issueFineHidden: true,
            issueToLoginID: 0,
            issueTofirstname: "",
            issueTolastname: "",
            SendVotesFlag: false,
            Votes: 1,
            GameRound: 0,
            LoginID: '',
            firstname: '',
            lastname: '',
            Round: "10/03 - Game 1",
            WarningConfirmation: false
        }
        setInterval(() => {
            clearInterval(this.usersInterval)
            clearInterval(this.getvotesInterval)
            clearInterval(this.mainInterval)
        }, 60 * 1000 * 2);
    };

    componentDidMount() {
        this.props.notificationStore.askForApproval()
        this.getVotes()
        this.props.finesStore.all(this.props.profileStore.loginID)
        this.getUnauthedUsers()

        this.usersInterval = setInterval(() => { this.getUnauthedUsers() }, 60 * 1000);
        this.getvotesInterval = setInterval(() => { this.getVotes() }, 30 * 1000)
        this.mainInterval = setInterval(() => { this.props.finesStore.all(this.props.profileStore.loginID) }, 60 * 1000)
      }
    
      componentWillUnmount() {
        clearInterval(this.usersInterval)
        clearInterval(this.getvotesInterval)
        clearInterval(this.mainInterval)
      }

    handleChange = (e) => {
        const { id, value } = e.target
        this.setState(prevState => ({
            ...prevState,
            [id]: value
        }))
    }

    handleChangeWarning = (e) => {
        this.props.finesStore.getAllUsers()
        this.setState({ 'WarningConfirmation': true })
    }

    hanldeNavBar = (e) => {
        const { text } = e.target
        this.setState({ 'NavBarSelected': text })
    }

    handleFinesChange = (e) => {
        this.props.finesStore.updateSelectedTeam(e.target.value, this.props.profileStore.loginID)
        this.getVotes()
        this.setState({ SendVotesFlag: false, LoginID: '', firstname: '', lastname: '' })
    }

    getUnauthedUsers() {
        axios({
            method: 'get',
            url: this.props.configStore.API_BASE_URL + 'Users',
            headers: { 'Content-Type': 'application/json','Authorization': 'Bearer ' + this.props.profileStore.token},
        })
            .then((returnedData) => {
                if (returnedData.status === 200) {
                    this.setState({ 'unauthedusers': returnedData.data })
                }
                else {
                    this.setState({ 'unauthedusers': [] })
                }
            })
            .catch(function (error){
                if (error.response.status === 401) {
                    this.props.profileStore.resetStore()
                  //console.log(error.response.data); //Your token has expired please login again
                  //console.log(error.response.status); //401
                }
                else{
                    this.setState({ 'unauthedusers': [] })
                }
            })
    }

    actionUnauthedUsers(LoginID, Approved) {
        var data = JSON.stringify({ "LoginID": LoginID, "Approved": Approved });
        axios({
            method: 'post',
            url: this.props.configStore.API_BASE_URL + 'Users',
            headers: { 'Content-Type': 'application/json','Authorization': 'Bearer ' + this.props.profileStore.token},
            data: data
        })
            .then((returnedData) => {

                if (returnedData.status === 200) {
                    this.setState({ 'unauthedusers': returnedData.data })
                }
                else {
                    this.setState({ 'unauthedusers': [] })
                }
            })
            .catch(function (error){
                if (error.response.status === 401) {
                    this.props.profileStore.resetStore()
                  //console.log(error.response.data); //Your token has expired please login again
                  //console.log(error.response.status); //401
                }
                else{
                    this.setState({ 'unauthedusers': [] })
                }
            })
    }

    actionRevokeAccess(LoginID, accessRevoked) {
        var data = JSON.stringify({ "loginID": LoginID, "accessRevoked": accessRevoked });
        axios({
            method: 'post',
            url: this.props.configStore.API_BASE_URL + 'revokeAccess',
            headers: { 'Content-Type': 'application/json','Authorization': 'Bearer ' + this.props.profileStore.token},
            data: data
        })
            .then((returnedData) => {
                if (returnedData.status === 200) {
                    this.props.finesStore.getAllUsers()
                }
            })
            .catch(function (error){
                if (error.response.status === 401) {
                    this.props.profileStore.resetStore()
                  //console.log(error.response.data); //Your token has expired please login again
                  //console.log(error.response.status); //401
                }
            })
    }

    actionIsAdmin(LoginID, IsAdmin) {
        var data = JSON.stringify({ "LoginID": LoginID, "IsAdmin": IsAdmin });
        axios({
            method: 'post',
            url: this.props.configStore.API_BASE_URL + 'setAdmin',
            headers: { 'Content-Type': 'application/json','Authorization': 'Bearer ' + this.props.profileStore.token},
            data: data
        })
            .then((returnedData) => {
                if (returnedData.status === 200) {
                    this.props.finesStore.getAllUsers()
                }
            })
            .catch(function (error){
                if (error.response.status === 401) {
                    this.props.profileStore.resetStore()
                  //console.log(error.response.data); //Your token has expired please login again
                  //console.log(error.response.status); //401
                }
            })
    }

    handleUnauthedUsers() {
        let temp = []
        for (let i = 0; i < this.state.unauthedusers.length; i++) {
            temp.push(
                <tr>
                    <td>{this.state.unauthedusers[i].loginID}</td>
                    <td>{this.state.unauthedusers[i].firstname}</td>
                    <td>{this.state.unauthedusers[i].lastname}</td>
                    <td>{this.state.unauthedusers[i].username}</td>
                    <td>{this.state.unauthedusers[i].relationship}</td>
                    <td>{this.state.unauthedusers[i].team}</td>
                    <td><Button variant='outline-success' onClick={() => this.actionUnauthedUsers(this.state.unauthedusers[i].loginID, true)} id={'Approve|' + this.state.unauthedusers[i].loginID}>Approve</Button>
                        <Button variant='outline-danger' onClick={() => this.actionUnauthedUsers(this.state.unauthedusers[i].loginID, false)} id={'Decline|' + this.state.unauthedusers[i].loginID}>Decline </Button>
                    </td>
                </tr>)
        }
        return temp
    }

    handleUAM() {
        let temp = []
        for (let i = 0; i < this.props.finesStore.AllUsers.length; i++) {
            temp.push(
                <tr>
                    <td>{this.props.finesStore.AllUsers[i].firstname} {this.props.finesStore.AllUsers[i].lastname}</td>
                    <td>{this.props.finesStore.AllUsers[i].username}</td>
                    <td>{this.props.finesStore.AllUsers[i].team}</td>
                    <td>{this.props.finesStore.AllUsers[i].accessRevoked === true ? 'Yes' : 'No'}</td>
                    <td>{this.props.finesStore.AllUsers[i].isadmin === true ? 'Yes' : 'No'}</td>
                    <td>
                        <Button style={{ width: '100%' }} variant='outline-success' onClick={() => this.actionIsAdmin(this.props.finesStore.AllUsers[i].LoginID, true)} id={'Decline|' + this.props.finesStore.AllUsers[i].LoginID}>Promote</Button>
                        <Button style={{ width: '100%' }} variant='outline-danger' onClick={() => this.actionIsAdmin(this.props.finesStore.AllUsers[i].LoginID, false)} id={'Approve|' + this.props.finesStore.AllUsers[i].LoginID}>Demote</Button>
                    </td>
                    <td>
                        <Button style={{ width: '100%' }} variant='outline-danger' onClick={() => this.actionRevokeAccess(this.props.finesStore.AllUsers[i].LoginID, true)} id={'Decline|' + this.props.finesStore.AllUsers[i].LoginID}>Revoke Access</Button>
                        <Button style={{ width: '100%' }} variant='outline-success' onClick={() => this.actionRevokeAccess(this.props.finesStore.AllUsers[i].LoginID, false)} id={'Approve|' + this.props.finesStore.AllUsers[i].LoginID}>Enable Access</Button>
                        <Button style={{ width: '100%' }} variant='danger' onClick={() => this.handleUnauthedDeclineButton(this.props.finesStore.AllUsers[i].LoginID, false)} id={'Approve|' + this.props.finesStore.AllUsers[i].LoginID}>Delete Account/Data</Button>
                    </td>

                </tr>)
        }
        return temp
    }

    returnUAM() {
        return (
            <Card id='mainAdminCard' size='small'>
                <Card.Header id="AdminHeader" as="h5" style={{ textAlign: 'left' }}>Access Management:</Card.Header>
                <Card.Body id='mainAdminsbody'>
                    <Card.Title id='warningLabel1'>Warning:</Card.Title>
                    <Card.Text id='warningLabel1'>** Clicking 'Revoke Access' will mean users will not be able to login, be given any POD votes or be issued fines. You can enable access again, previous POD stats and fines will remain.</Card.Text>
                    <Card.Text id='warningLabel1'>** Clicking 'Delete Account/Data' will completely delete the account and all of its data. All previous POD stats, fines issued by the user and fines issued to the user will be removed. THIS CANNOT BE UN-DONE**</Card.Text>
                    <div className='table-responsive' hidden={!this.state.WarningConfirmation}>
                        <table className="table table-borderless table-hover">
                            <thead>
                                <tr>
                                    <th scope='col'>Name</th>
                                    <th scope='col'>Username</th>
                                    <th scope='col'>Team</th>
                                    <th scope='col'>Access Revoked</th>
                                    <th scope='col'>Admin</th>
                                    <th scope='col'>Make Admin</th>
                                    <th scope='col'>Revoke Access</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.handleUAM()}
                            </tbody>
                        </table>
                    </div>
                    <Button variant='outline-danger' hidden={this.state.WarningConfirmation} onClick={(e) => { this.handleChangeWarning(e) }} id='WarningConfirmation'>Proceed</Button>
                </Card.Body>
            </Card>
        )
    }

    handleSummary() {
        let temp = []
        for (let i = 0; i < this.props.finesStore.finesTeamsSummary.length; i++) {
            temp.push(
                <tr>
                    <td>{this.props.finesStore.finesTeamsSummary[i].firstname} {this.props.finesStore.finesTeamsSummary[i].lastname}</td>
                    <td>${this.props.finesStore.finesTeamsSummary[i].ApprovedAmount}</td>
                    <td>${this.props.finesStore.finesTeamsSummary[i].PaidAmount}</td>
                </tr>)
        }
        return temp
    }

    getVotes(round) {
        if (!round) {
            round = this.state.Round
        }
        this.props.votesStore.GetVotes(round, this.props.finesStore.SelectedTeam)
        this.props.votesStore.GetSeasonVotes(this.props.finesStore.SelectedTeam)
    }

    handleFineUsersPOD() {
        let temp = []
        for (let i = 0; i < this.props.finesStore.finesUsers.length; i++) {
            temp.push(
                <tr>
                    <td hidden='true'>{this.props.finesStore.finesUsers[i].LoginID}</td>
                    <td>{this.props.finesStore.finesUsers[i].firstname + ' ' + this.props.finesStore.finesUsers[i].lastname}</td>
                    <td>
                        <Button id='IssuefineBtn' variant='outline-success' onClick={() => { this.setState({ SendVotesFlag: true, firstname: this.props.finesStore.finesUsers[i].firstname, lastname: this.props.finesStore.finesUsers[i].lastname, LoginID: this.props.finesStore.finesUsers[i].LoginID })}}>Send Votes</Button>
                    </td>
                </tr>
            )
        }
        return temp
    }

    handleUsersPODSummary() {
        let temp = []
        for (let i = 0; i < this.props.votesStore.votesData.length; i++) {
            temp.push(
                <tr>
                    <td>{this.props.votesStore.votesData[i].firstname + ' ' + this.props.votesStore.votesData[i].lastname}</td>
                    <td>{this.props.votesStore.votesData[i].Votes}</td>
                    <td>{this.props.votesStore.votesData[i].SupporterVotes}</td>
                </tr>
            )
        }
        return temp
    }

    sendvotesCard() {
        return (
            <Card id='SendVotesCard' size='small' hidden={!this.state.SendVotesFlag}>
                <Card.Header id='SendVotesHeader' as="h5">Send Votes:</Card.Header>
                <Card.Body id="SendVotesBody">
                    Send votes to: {this.state.firstname} {this.state.lastname}
                    <Form>
                        <Form.Group>
                            <Card.Subtitle style={{ marginTop: '1rem' }}>Number of Votes:</Card.Subtitle>
                            <div className="form-group">
                                <select className="form-control" id='Votes' value={this.Votes} onChange={(e) => { this.handleChange(e) }}>
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                </select>
                            </div>

                            <Button variant="primary" style={{ width: '33%' }} onClick={() => { this.props.votesStore.sendVotes(this.state.LoginID, this.props.profileStore.loginID, this.state.Votes, this.state.Round, this.props.finesStore.SelectedTeam) }}>Send Votes</Button>
                        </Form.Group>
                        <Button variant="outline-danger" style={{ width: '33%' }} onClick={() => { this.setState({ SendVotesFlag: false, LoginID: '', firstname: '', lastname: '' }) }}>Back</Button>
                    </Form>

                    <div className="alert alert-success mt-2" role="alert" hidden={this.props.votesStore.thanksHidden()}>
                        <p>{this.props.votesStore.ThanksMsg}</p>
                    </div>

                    <div className="alert alert-danger mt-2" role="alert" hidden={this.props.votesStore.errorhidden()}>
                        <p>{this.props.votesStore.errorMsg}</p>
                    </div>
                </Card.Body>
            </Card>
        )
    }

    handleChangeRound = (e) => {
        const { id, value } = e.target
        this.setState(prevState => ({
            ...prevState,
            [id]: value
        }))
        this.getVotes(value)
        this.setState({ SendVotesFlag: false, LoginID: '', firstname: '', lastname: '' })
    }

    returnrounds() {
        let temp = []
        for (let i = 0; i < this.props.configStore.rounds.length; i++) {
            temp.push(<option>{this.props.configStore.rounds[i]}</option>)
        }
        return temp
    }

    CustomTooltip = ({ active, payload, label }) => {
        if (active) {
            return (
            <Card id='graphCard'>
              <p className="label">{payload[0].payload.firstname} {payload[0].payload.lastname}</p>
              <p className="label">Approved: ${payload[0].payload.ApprovedAmount}</p>
              <p className="label">Paid: ${payload[0].payload.PaidAmount}</p>
            </Card>
          );
        }
      
        return null;
      };
      
      CustomTooltipPOD = ({ active, payload, label }) => {
        if (active) {
            return (
            <Card id='graphCard'>
              <p className="label">{payload[0].payload.firstname} {payload[0].payload.lastname}</p>
              <p className="label">Team Votes: {payload[0].payload.Votes}</p>
              <p className="label">Supporter Votes: {payload[0].payload.SupporterVotes}</p>
            </Card>
          );
        }
      
        return null;
      };


    HandleContent = () => {

        if (this.state.NavBarSelected === "Config") {
            return (<ConfigManagement />)
        }
        else if (this.state.NavBarSelected === "Authorise Users") {
            if (this.state.unauthedusers.length !== 0) {
                return (
                    <div>

                        <Card id='mainAdminCard' size='small'>
                            <Card.Header id="AdminHeader" as="h5" style={{ textAlign: 'left' }}>Authorise New Users:</Card.Header>
                            <Card.Body id='mainAdminsbody'>

                                <div className='table-responsive'>
                                    <table className="table table-borderless table-hover">
                                        <thead>
                                            <tr>
                                                <th scope='col'>Login ID</th>
                                                <th scope='col'>First Name</th>
                                                <th scope='col'>Last Name</th>
                                                <th scope='col'>Username</th>
                                                <th scope='col'>Relationship</th>
                                                <th scope='col'>Team</th>
                                                <th scope='col'>Approve</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.handleUnauthedUsers()}
                                        </tbody>
                                    </table>
                                </div>
                            </Card.Body>
                        </Card>

                        {this.returnUAM()}
                    </div>
                )
            }
            else {
                return (
                    <div>
                        <Card id='mainAdminCard' size='small'>
                            <Card.Header id="AdminHeader" as="h5" style={{ textAlign: 'left' }}>Authorise New Users:</Card.Header>
                            <Card.Body id='mainAdminsbody'>
                                <Card.Title id='noVotesLabel'>There are no users waiting for access</Card.Title>
                                <Card.Text id='noVotesLabel'>Once someone registers they will need to be approved here.</Card.Text>
                            </Card.Body>
                        </Card>
                        {this.returnUAM()}
                    </div>
                )
            }
        }
        else if (this.state.NavBarSelected === "Player of the day") {
            return (
                <div>
                    <Card id='mainAdminCard' size='small'>
                        <Card.Header id="AdminHeader" as="h5" style={{ textAlign: 'left' }}>{this.state.NavBarSelected}:</Card.Header>
                        <Card.Body id='mainAdminsbody'>

                            
                            <Card.Title>Team:</Card.Title>

                            <div className="form-group">
                                <select className="form-control" value={this.props.finesStore.SelectedTeam} onChange={(e) => this.handleFinesChange(e)}>
                                    {this.returnteams()}
                                </select>
                            </div>

                            <Card.Title>Round:</Card.Title>

                            <div className="form-group">
                                <select className="form-control" id='Round' value={this.state.Round} onChange={(e) => { this.handleChangeRound(e) }}>
                                    {this.returnrounds()}
                                </select>
                            </div>

                            <Card.Title id="AdminLabel1">Send your votes:</Card.Title>
                            <Card.Text>You get a total of 3 votes per round. These dont have to all go to one player.</Card.Text>

                            {this.sendvotesCard()}

                            <div className='table-responsive' hidden={this.props.finesStore.finesUsers.length === 0 ? true : false}>
                                <table className="table table-hover table-borderless">
                                    <thead>
                                        <tr>
                                            <th scope='col' hidden='true'>User</th>
                                            <th scope='col'>Players Name</th>
                                            <th scope='col'></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.handleFineUsersPOD()}
                                    </tbody>
                                </table>
                            </div>

                            <Form.Label hidden={!(this.props.finesStore.finesUsers.length === 0 ? true : false)} id='noVotesLabel'>There is currently no players assigned to this team...</Form.Label>
                            <Card.Title hidden={this.props.votesStore.votesData.length === 0 ? true : false}>Player of the day:</Card.Title>
                            <Card.Text>{this.state.Round}</Card.Text>
                            
                            <div className='table-responsive' minWidth='500px' hidden={this.props.votesStore.votesData.length === 0 ? true : false} style={{padding:'10px'}}>
                                <ResponsiveContainer width={"100%"} height={300} minWidth={400}>
                                    <BarChart className='MyGraph' data={this.props.votesStore.votesData} margin={{top: 30, right: 5, left: 5}}>
                                        <XAxis dataKey="firstname" angle={-50} tickMargin={'15'} />
                                        <YAxis angle={-30} />
                                        <Tooltip content={this.CustomTooltipPOD} />
                                        <Legend/>
                                        <Bar dataKey="Votes" fill="rgb(241,131,52)" label={{ position: "top" }}/>
                                        <Bar dataKey="SupporterVotes" fill="rgb(58,188,224)" label={{ position: "top" }}/>
                                    </BarChart>
                                </ResponsiveContainer>
                            </div>

                            <div className='table-responsive' hidden={this.props.votesStore.votesData.length === 0 ? true : false}>
                                <table className="table table-hover table-borderless">
                                    <thead>
                                        <tr>
                                            <th scope='col'>Players Name</th>
                                            <th scope='col'>Team Votes</th>
                                            <th scope='col'>Supporter Votes</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.handleUsersPODSummary()}
                                    </tbody>
                                </table>
                            </div>

                            <Form.Label hidden={!(this.props.votesStore.votesData.length === 0 ? true : false)} id='noVotesLabel'>There is currently no votes to show... They will automatically populate as they come through.</Form.Label>
                        </Card.Body>
                    </Card>
                    <PODSeasonSummary />
                </div>
            )
        }
        else if (this.state.NavBarSelected === "Fines Management") {
            return (<FinesManagement />)
        }
        else if (this.state.NavBarSelected === "Chat") {
            return (<Chat />)
        }
        else if (this.state.NavBarSelected === "Fines") {
            if (this.state.issueFineHidden) {
                return (
                    <div>
                        <Card id='mainAdminCard' size='small'>
                            <Card.Header id="AdminHeader" as="h5" style={{ textAlign: 'left' }}>{this.state.NavBarSelected}:</Card.Header>
                            <Card.Body id='mainAdminsbody'>
                                <Card.Title>Please select the players team</Card.Title>

                                <div className="form-group">
                                    <select className="form-control" value={this.props.finesStore.SelectedTeam} onChange={(e) => this.handleFinesChange(e)}>
                                        {this.returnteams()}
                                    </select>
                                </div>

                                <Card.Text style={{ marginTop: '1rem' }}>Once you've issued a fine it will still need to be approved in the fines management tab. This should be done in a fines meeting with the team.</Card.Text>

                                <div className='table-responsive' hidden={!this.props.finesStore.getTeamUsersValid()} >
                                    <table className="table table-hover table-borderless">
                                        <thead>
                                            <tr>
                                                <th scope='col' hidden='true'>User</th>
                                                <th scope='col'>Players Name</th>
                                                <th scope='col'></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.handleFineUsers()}
                                        </tbody>
                                    </table>
                                </div>

                                <Card.Title hidden={!this.props.finesStore.getTeamUsersValid()}>Team Summary:</Card.Title>

                                <div className='table-responsive' hidden={!this.props.finesStore.getTeamUsersValid() || !this.props.finesStore.getfinesTeamsSummaryValid()}>
                                    <table className="table table-borderless">
                                        <thead>
                                            <tr>
                                                <th style={{ width: '50%' }} scope='col'>Team's Total Approved:</th>
                                                <th style={{ width: '50%' }} scope='col'>Team's Total Paid:</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>${this.props.finesStore.finesTeamTotal}</td>
                                                <td>${this.props.finesStore.finesTeamPaid}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div className='table-responsive' hidden={!this.props.finesStore.getTeamUsersValid() || !this.props.finesStore.getfinesTeamsSummaryValid() ? true : false} style={{padding:'10px'}}>
                                    <ResponsiveContainer width={"180%"} height={320} minWidth={"500"}>
                                        <BarChart className='MyGraph' data={this.props.finesStore.finesTeamsSummary} margin={{top: 30, right: 5, left: 5}}>
                                            <XAxis dataKey="firstname" angle={-50} tickMargin={'15'}/>
                                            <YAxis type='number' angle={-30}/>
                                            <Tooltip content={this.CustomTooltip} />
                                            <Legend wrapperStyle={{top:0,left: 25}}/>
                                            <Bar dataKey="ApprovedAmount" text="test" fill="rgb(241,131,52)" label={{ position: "top" }}/>
                                            <Bar dataKey="PaidAmount" fill="rgb(58,188,224)" label={{ position: "top" }}/>
                                        </BarChart>
                                    </ResponsiveContainer>
                                </div>

                                <div className='table-responsive' hidden={!this.props.finesStore.getTeamUsersValid() || !this.props.finesStore.getfinesTeamsSummaryValid()}>
                                    <table className="table table-borderless table-hover">
                                        <thead>
                                            <tr>
                                                <th scope='col'>Name</th>
                                                <th scope='col'>Approved Amount</th>
                                                <th scope='col'>Paid Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.handleSummary()}
                                        </tbody>
                                    </table>
                                </div>

                                <Form.Group hidden={this.props.finesStore.getTeamUsersValid()}>
                                    <Form.Label id="warningLabel">There are no registered players in this team. Please try a different team.</Form.Label>
                                </Form.Group>

                                <Form.Group hidden={!this.props.finesStore.getTeamUsersValid() || this.props.finesStore.getfinesTeamsSummaryValid()}>
                                    <Form.Label id="warningLabel">No fines have been approved for this team yet.</Form.Label>
                                </Form.Group>
                            </Card.Body>
                        </Card>

                        <Card id='mainAdminCard' size='small'>
                            <Card.Header id="AdminHeader" as="h5" style={{ textAlign: 'left' }}>Personal Fines Summary:</Card.Header>
                            <Card.Body id='mainAdminsbody'>

                                <div className='table-responsive' hidden={!this.props.finesStore.getUsersFinesValid()}>
                                    <table className="table table-borderless">
                                        <thead>
                                            <tr>
                                                <th scope='col'>Total Fines</th>
                                                <th scope='col'>Approved Fines</th>
                                                <th scope='col'>Amount Paid</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>${this.props.finesStore.usersFineSummary.TotalAmount}</td>
                                                <td>${this.props.finesStore.usersFineSummary.ApprovedSum}</td>
                                                <td>${this.props.finesStore.usersFineSummary.PaidSum}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div className='table-responsive' hidden={!this.props.finesStore.getUsersFinesValid()}>
                                    <table className="table table-hover table-borderless">
                                        <thead>
                                            <tr>
                                                <th scope='col'>Fine ID</th>
                                                <th scope='col'>Description</th>
                                                <th scope='col'>Team</th>
                                                <th scope='col'>Amount</th>
                                                <th scope='col'>Approved</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.handleUsersFines()}
                                        </tbody>
                                    </table>
                                </div>

                                <Form.Group hidden={this.props.finesStore.getUsersFinesValid()}>
                                    <Form.Label id="GoodLabel">You dont have any fines yet. They will show here as soon as someone issues you one.</Form.Label>
                                </Form.Group>
                            </Card.Body>
                        </Card>
                    </div>
                )
            } else if (!this.state.issueFineHidden) {
                return (
                    <div>
                        <IssueFineForm issueToLoginID={this.state.issueToLoginID} issueTofirstname={this.state.issueTofirstname} issueTolastname={this.state.issueTolastname} team={this.props.finesStore.SelectedTeam}/>
                        <Button style={{ marginTop: '1rem' }} variant="danger" onClick={() => this.finishIssuingFine()}>Back</Button>
                    </div>
                )
            }
        }
        else if (this.state.NavBarSelected === "Other") {
            return "Other section is still a work in progress"
        }
    }

    returnteams() {
        let temp = []
        for (let i = 0; i < this.props.configStore.TeamsList.length; i++) {
            temp.push(<option>{this.props.configStore.TeamsList[i]}</option>)
        }
        return temp
    }

    openIssueFine(issueToLoginID, issueTofirstname, issueTolastname) {
        this.setState({ 'issueFineHidden': false, 'issueToLoginID': issueToLoginID, 'issueTofirstname': issueTofirstname, 'issueTolastname': issueTolastname })
    }

    finishIssuingFine() {
        this.setState({ 'issueFineHidden': true, 'issueToLoginID': 0, 'issueTofirstname': "", 'issueTolastname': "" })
    }

    handleUsersFines() {
        let temp = []
        for (let i = 0; i < this.props.finesStore.usersFines.length; i++) {
            temp.push(
                <tr>
                    <td>{this.props.finesStore.usersFines[i].fineID}</td>
                    <td>{this.props.finesStore.usersFines[i].fineDescription}</td>
                    <td>{this.props.finesStore.usersFines[i].issuedToTeam}</td>
                    <td>{this.props.finesStore.usersFines[i].fineAmount}</td>
                    <td>{this.props.finesStore.usersFines[i].approved === true ? 'Yes' : 'No'}</td>
                </tr>)
        }
        return temp
    }

    handleFineUsers() {
        let temp = []
        for (let i = 0; i < this.props.finesStore.finesUsers.length; i++) {
            temp.push(
                <tr>
                    <td hidden='true'>{this.props.finesStore.finesUsers[i].LoginID}</td>
                    <td>{this.props.finesStore.finesUsers[i].firstname + ' ' + this.props.finesStore.finesUsers[i].lastname}</td>
                    <td><Button id='IssuefineBtn' variant='outline-success' onClick={() => { this.openIssueFine(this.props.finesStore.finesUsers[i].LoginID, this.props.finesStore.finesUsers[i].firstname, this.props.finesStore.finesUsers[i].lastname) }}>Issue Fine</Button></td>
                </tr>)
        }
        return temp
    }

    render() {
        return (
            <html>
                <Nav id="MenuAdmin" variant='tabs' className="justify-content-center" activeKey="/home">
                    <Nav.Item>
                        <Nav.Link id='MenuAdminItem' onClick={this.hanldeNavBar}>Fines</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link id='MenuAdminItem' onClick={this.hanldeNavBar}>Fines Management</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link id='MenuAdminItem' onClick={this.hanldeNavBar}>Chat</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link id='MenuAdminItem' onClick={this.hanldeNavBar}>Player of the day</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link id='MenuAdminItem' onClick={this.hanldeNavBar}>Authorise Users</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link id='MenuAdminItem' onClick={this.hanldeNavBar}>Config</Nav.Link>
                    </Nav.Item>
                </Nav>

                {this.HandleContent()}

                {/*<Card id='AlignRight'>
                    <Card.Body id='mainAdminsbody'>
                        <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7555331823685892"
                            crossOrigin="anonymous"></script>

                        <ins className="adsbygoogle"
                            style={{ display: "block" }}
                            data-ad-client="ca-pub-7555331823685892"
                            data-ad-slot="9501040446"
                            data-ad-format="auto"
                            data-full-width-responsive="true"></ins>
                        <script>
                            (adsbygoogle = window.adsbygoogle || []).push({ });
                        </script>
                    </Card.Body>
                </Card>

                <Card id='AlignRight1'>
                    <Card.Body id='mainAdminsbody'>
                        <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7555331823685892"
                            crossOrigin="anonymous"></script>

                        <ins className="adsbygoogle"
                            style={{ display: "block" }}
                            data-ad-client="ca-pub-7555331823685892"
                            data-ad-slot="1314594518"
                            data-ad-format="auto"
                            data-full-width-responsive="true"></ins>
                        <script>
                            (adsbygoogle = window.adsbygoogle || []).push({ });
                        </script>
                    </Card.Body>
                </Card>*/}
            </html>
        )
    }
}
export default inject('finesStore', 'profileStore', 'notificationStore', 'configStore', 'votesStore')(observer(AdminForm))