import React from 'react';
import Header from '../../components/Header/Header'
import LoginFormNew from '../../components/LoginForm/LoginForm'
import AdminForm from '../../components/AdminForm/AdminForm'
import UsersForm from '../../components/UsersForm/UsersForm'
import { observer, inject } from 'mobx-react';
import SecondaryHeader from '../../components/Secondary_Header/Secondary_Header';

class AdminPage extends React.Component {
    render() {
        if (!this.props.profileStore.loggedin) {
            return (
                <div>
                    <Header title="Roosters Login" name="" loginshow={false} />

                    <div className="container d-flex align-items-center flex-column">
                        <LoginFormNew />
                    </div>

                </div>
            )
        }
        else if (!this.props.profileStore.isactive) {
            return (
                <div>
                    <Header title="Roosters Login" name="" loginshow={false} />

                    <div className="container d-flex align-items-center flex-column">
                        <LoginFormNew />
                    </div>

                </div>
            )
        }
        else if (this.props.profileStore.admin) {
            return (
                <div>
                    <Header title="Welcome" name={this.props.profileStore.firstname} loginshow={true} />
                    <SecondaryHeader />
                    <div className="container d-flex align-items-center flex-column">
                        <AdminForm />
                    </div>

                </div>
            )
        }
        else {
            return (<div>
                <Header title="Welcome" name={this.props.profileStore.firstname} loginshow={true} />

                <div className="container d-flex align-items-center flex-column">
                    <UsersForm />
                </div>

            </div>
            )
        }
    }
}
export default inject('profileStore')(observer(AdminPage))