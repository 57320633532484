import React from 'react';
import './RegisterForm.css';
import axios from 'axios';
import { Card, Button, Form, Image } from 'react-bootstrap'
import { observer, inject } from 'mobx-react';

class LoginFormNew extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            firstname: "",
            lastname: "",
            relationship: "Player",
            team: "Mens DIV1",
            confirmpassword: "",
            username: "",
            password: "",
            successMessage: "",
            refresh_token: null,
            successMessageHidden: true,
            error: "",
            errorHidden: true,
            LoadingHidden: true,
        };
    };

    componentDidMount() {
        localStorage.clear()
    }

    handleChange = (e) => {
        const { id, value } = e.target
        this.setState(prevState => ({
            ...prevState,
            [id]: value
        }))

        if (id.toUpperCase() === 'RELATIONSHIP' && (value.toUpperCase() !== "PLAYER" && value.toUpperCase() !== "COACH/MANAGER")) {
            this.setState({ 'team': 'SUPPORTER' })
        }

        if (id.toUpperCase() === "TEAM" && this.state.relationship.toUpperCase() !== "PLAYER" && this.state.relationship.toUpperCase() !== "COACH/MANAGER") {
            this.setState({ 'team': 'SUPPORTER' })
        }
    }

    errorShow(errorMessage, errorHidden) {
        this.setState({ 'error': errorMessage, 'errorHidden': errorHidden })
    }

    successShow(successMessage, successHidden) {
        this.setState(prevState => ({
            ...prevState,
            'successMessage': successMessage,
            'successMessageHidden': successHidden
        }))
    }

    LoadingShow(LoadingHidden) {
        this.setState(prevState => ({
            ...prevState,
            'LoadingHidden': LoadingHidden
        }))
    }

    sendSignUpAPIRequest = () => {
        this.LoadingShow(false)
        this.errorShow('', true)
        this.successShow('', true)
        var data = JSON.stringify({
            "username": this.state.username, "password": this.state.password,
            'firstname': this.state.firstname, 'lastname': this.state.lastname,
            'relationship': this.state.relationship, 'team': this.state.team
        });

        axios({
            method: 'post',
            url: this.props.configStore.API_BASE_URL + 'SignUp',
            headers: { 'Content-Type': 'application/json' },
            data: data
        })
            .then((returnedData) => {
                if (returnedData.status === 200) {
                    this.LoadingShow(true)
                    this.errorShow("", true)
                    this.successShow("Thank you for registering... Once access is approved you'll be able to login and vote.", false)
                }
                else {
                    this.LoadingShow(true)
                    this.errorShow('Something happened - Unsuccessful', false)
                    this.successShow('', true)
                }
            })
            .catch(() => {
                this.LoadingShow(true)
                this.errorShow('That failed - Unsuccessful. The username you are using could already be in use.', false)
                this.successShow('', true)
            })
    }

    handleSubmitClick = (e) => {
        e.preventDefault();

        if (this.state.username.length === "" || this.state.password.length === "" || this.state.confirmpassword === "" || this.state.firstname === "" || this.state.lastname === "" || this.state.relationship === "") {
            console.log(this.state.relationship)

            this.errorShow("Please complete all of the fields above prior to registering.", false)
            this.successShow('', true)
        }
        else if (this.state.username.length < 5) {
            this.errorShow("You'll need a longer Username to proceed.", false)
            this.successShow('', true)
        }
        else if (this.state.password.length < 5) {
            this.errorShow("You'll need a longer Password to proceed.", false)
            this.successShow('', true)
        }
        else if (this.state.password !== this.state.confirmpassword) {
            this.errorShow("Please make sure both passwords match", false)
            this.successShow('', true)
        }
        else if (this.state.relationship.toUpperCase() === 'PLAYER' && this.state.team === "") {
            this.errorShow("If you selected player then please provide the team you are in. You can add additional teams later.", false)
            this.successShow('', true)
        }
        else {
            this.setState(prevState => ({
                ...prevState,
                'firstname': "",
                'lastname': "",
                'relationship': "",
                'team': "",
                'password': "",
                'confirmpassword': ""
            }))
            this.sendSignUpAPIRequest()
        }
    }

    returnteams() {
        let temp = []
        for (let i = 0; i < this.props.configStore.TeamsListRegistration.length; i++) {
            temp.push(<option>{this.props.configStore.TeamsListRegistration[i]}</option>)
        }
        return temp
    }

    render() {
        return (
            <div>
                <Card id='registerCard'>
                    <Card.Header id="registerHeader" as='h5'>Register to be a Rooster:</Card.Header>
                    <Card.Body id='cardheader'>
                        <Form hidden={!this.state.successMessageHidden || !this.state.LoadingHidden}>
                            <Form.Group>
                                <Form.Text>This will allow you to vote for your player of the day and issue players with fines, which will then be raised in the next fines meeting.</Form.Text>
                                <Form.Label>First Name: </Form.Label>
                                <Form.Control id="firstname" value={this.state.firstname} onChange={this.handleChange} />

                                <Form.Label>Last Name: </Form.Label>
                                <Form.Control id="lastname" value={this.state.lastname} onChange={this.handleChange} />

                                <Form.Label>Roosters Relationship: </Form.Label>
                                <div className="form-group">
                                    <select className="form-control" id='relationship' value={this.state.relationship} onChange={(e) => this.handleChange(e)}>
                                        <option>Player</option>
                                        <option>Coach/Manager</option>
                                        <option>Parent</option>
                                        <option>Supporter</option>
                                    </select>
                                </div>

                                <Form.Label>Main Team:</Form.Label>
                                <Form.Text>You can add an additional team once registered.</Form.Text>
                                <div className="form-group">
                                    <select className="form-control" id="team" value={this.state.team} onChange={this.handleChange}>
                                        {this.returnteams()}
                                    </select>
                                </div>

                                <Form.Label>Email: </Form.Label>
                                <Form.Control id="username" placeholder="Enter a username" minlength='5' value={this.state.username} onChange={this.handleChange} />

                                <Form.Label>Password: </Form.Label>
                                <Form.Control type="password" id="password" placeholder="Password" minLength='5' value={this.state.password} onChange={this.handleChange} />

                                <Form.Label>Confirm Password: </Form.Label>
                                <Form.Control type="password" id="confirmpassword" placeholder="Password" minLength='5' value={this.state.confirmpassword} onChange={this.handleChange} />
                                <div style={{ textAlign: 'center' }}>
                                    <Button style={{ marginTop: '1rem', minWidth: '30%' }} variant="outline-success" type="submit" onClick={this.handleSubmitClick}>Register</Button>
                                    <Form.Text>Already have an account?</Form.Text>
                                    <Form.Text><a href="/Loggedin">Login Here</a></Form.Text>
                                </div>

                            </Form.Group>
                        </Form>

                        <div id='contents'>
                            <Image hidden={this.state.LoadingHidden} id="LoadingImage" src={process.env.PUBLIC_URL + '/Loading.jpg'} fluid ></Image>
                        </div>

                        <div className="alert alert-success mt-2" role="alert" hidden={this.state.successMessageHidden}>
                            {this.state.successMessage}
                        </div>
                        <div className="alert alert-danger mt-2" role="alert" hidden={this.state.errorHidden}>
                            {this.state.error}
                        </div>

                        <Button style={{ marginTop: '1rem' }} hidden={this.state.successMessageHidden} variant="primary" type="submit" href='/'>Return Home for now</Button>
                    </Card.Body>
                </Card>
            </div>
        )
    }
}
export default inject('configStore')(observer(LoginFormNew))